import axios from 'axios';

const BASE_URL = 'https://nodejs-appli.visamonde.com';

// Fonction pour convertir une data URI en Blob
const dataURItoBlob = (dataURI) => {
  const [header, base64Data] = dataURI.split(',');
  const isBase64 = header.indexOf('base64') >= 0;
  let byteString;
  if (isBase64) {
    byteString = atob(base64Data);
  } else {
    byteString = decodeURIComponent(base64Data);
  }
  const mimeString = header.split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const validerDemande = async (data) => {
  const formData = new FormData();

  // Parcours de tous les champs du formulaire
  for (const [key, value] of Object.entries(data)) {
    // 1) Gestion spéciale de optionalServices (objet)
    if (key === 'optionalServices' && typeof value === 'object') {
      // On convertit l'objet en JSON pour pouvoir l'envoyer correctement
      formData.append(key, JSON.stringify(value));
      continue;
    }

    // 2) Si c'est une string qui commence par "data:" → c'est un fichier en base64
    if (typeof value === 'string' && value.startsWith('data:')) {
      const blob = dataURItoBlob(value);
      let ext = '';
      if (blob.type === 'image/jpeg') {
        ext = 'jpg';
      } else if (blob.type === 'image/png') {
        ext = 'png';
      } else if (blob.type === 'application/pdf') {
        ext = 'pdf';
      } else {
        ext = 'bin';
      }
      formData.append(key, blob, `${key}.${ext}`);
      continue;
    }

    // 3) Si c'est une URL de fichier (http ou file)
    if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('file:'))) {
      try {
        const response = await fetch(value);
        const blob = await response.blob();
        let ext = '';
        if (blob.type === 'image/jpeg') {
          ext = 'jpg';
        } else if (blob.type === 'image/png') {
          ext = 'png';
        } else if (blob.type === 'application/pdf') {
          ext = 'pdf';
        } else {
          ext = 'bin';
        }
        formData.append(key, blob, `${key}.${ext}`);
      } catch (error) {
        console.error(`Erreur lors de la récupération du fichier pour ${key}:`, error);
        // On ajoute la valeur brute en fallback
        formData.append(key, value);
      }
      continue;
    }

    // 4) Autres cas : champ texte standard ou nombre, etc.
    formData.append(key, value);
  }

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post(`${BASE_URL}/validerDemande`, formData, config);
    console.log('Demande validée avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la validation de la demande:', error);
  }
};

export default validerDemande;
