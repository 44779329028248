import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  IconButton,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 * Remplace AsyncStorage par localStorage pour stocker l'URL.
 */
const storeUrlInLocalStorage = (url) => {
  try {
    localStorage.setItem('pays_url_infos', url);
    console.log('URL stockée:', url);
  } catch (e) {
    console.error("Erreur lors de la sauvegarde de l'URL:", e);
  }
};

const PaysGrid = ({ paysVisibles, onSelectPays }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh', // Occupe toute la hauteur de la fenêtre
        overflowY: 'auto',
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        {paysVisibles.map((pays, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: 3,
                m: 1,
                textAlign: 'center',
              }}
            >
              {/* Zone cliquable principale pour sélectionner le pays */}
              <CardActionArea onClick={() => onSelectPays(pays.id)}>
                <Box sx={{ mt: 1 }}>
                  <img
                    src={pays.url_flag}
                    alt={`${pays.libelle} flag`}
                    style={{
                      width: 40,
                      height: 30,
                      objectFit: 'contain',
                    }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    {pays.libelle}
                  </Typography>
                  <Box sx={{ mb: 1 }}>
                    <img
                      src={pays.url_image}
                      alt={pays.libelle}
                      style={{
                        width: '90%',
                        height: 100,
                        objectFit: 'cover',
                        borderRadius: 10,
                      }}
                    />
                  </Box>
                </CardContent>
              </CardActionArea>

              {/* Bouton d'info, en dehors de la zone principale afin d'éviter les conflits de clic */}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Empêche le déclenchement de la sélection du pays
                  const fullUrl = pays.url_infos + '?appli=yes';
                  storeUrlInLocalStorage(fullUrl);
                  window.open(fullUrl, '_blank');
                }}
                sx={{
                  width: 40,
                  height: 40,
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <HelpOutlineIcon sx={{ fontSize: 30, color: 'red' }} />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PaysGrid;
