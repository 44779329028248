// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import SignUpScreen from './components/SignUpScreen';
import ForgotPasswordScreen from './components/ForgotPasswordScreen';
import MyTabs from './components/MyTabs';
import ParameterScreen from './screens/ParameterScreen';
import VoyageurInsert from './components/VoyageurInsert';
import VoyageurUpdate from './components/VoyageurUpdate';
import AdresseFacturation from './components/AdresseFacturation';

import FormInde from './formulaires/FormInde';
import FormArabieSaoudite from './formulaires/FormArabieSaoudite';
import FormAustralie from './formulaires/FormAustralie';
import FormBahrein from './formulaires/FormBahrein';
import FormBenin from './formulaires/FormBenin';
import FormCambodge from './formulaires/FormCambodge';
import FormCameroun from './formulaires/FormCameroun';
import FormCanada from './formulaires/FormCanada';
import FormCapVert from './formulaires/FormCapVert';
import FormCoreeDuSud from './formulaires/FormCoreeDuSud';
import FormCoteIvoire from './formulaires/FormCoteIvoire';
import FormDjibouti from './formulaires/FormDjibouti';
import FormDubai from './formulaires/FormDubai';
import FormEgypte from './formulaires/FormEgypte';
import FormEthiopie from './formulaires/FormEthiopie';
import FormGuinee from './formulaires/FormGuinee';
import FormIndonesie from './formulaires/FormIndonesie';
import FormJordanie from './formulaires/FormJordanie';
import FormKenya from './formulaires/FormKenya';
import FormKoweit from './formulaires/FormKoweit';
import FormLaos from './formulaires/FormLaos';
import FormMadagascar from './formulaires/FormMadagascar';
import FormMyanmar from './formulaires/FormMyanmar';
import FormNouvelleZelande from './formulaires/FormNouvelleZelande';
import FormOman from './formulaires/FormOman';
import FormOuganda from './formulaires/FormOuganda';
import FormPapouasieNG from './formulaires/FormPapouasieNG';
import FormSeychelles from './formulaires/FormSeychelles';
import FormSriLanka from './formulaires/FormSriLanka';
import FormTanzanie from './formulaires/FormTanzanie';
import FormTogo from './formulaires/FormTogo';
import FormTurquie from './formulaires/FormTurquie';
import FormUSA from './formulaires/FormUSA';
import FormVietnam from './formulaires/FormVietnam';

import ClassiqueFormArabieSaoudite from './formulaires/ClassiqueFormArabieSaoudite';

function App() {
  return (
    <Router>
      <Routes>

      <Route path="/formulaire_vietnam" element={<FormVietnam />} />
      <Route path="/formulaire_usa" element={<FormUSA />} />
      <Route path="/formulaire_turquie" element={<FormTurquie />} />
      <Route path="/formulaire_togo" element={<FormTogo />} />
      <Route path="/formulaire_tanzanie" element={<FormTanzanie />} />
      <Route path="/formulaire_sri_lanka" element={<FormSriLanka />} />
      <Route path="/formulaire_seychelles" element={<FormSeychelles />} />
      <Route path="/formulaire_papouasie_ng" element={<FormPapouasieNG />} />
      <Route path="/formulaire_ouganda" element={<FormOuganda />} />
      <Route path="/formulaire_oman" element={<FormOman />} />
      <Route path="/formulaire_nouvelle_zelande" element={<FormNouvelleZelande />} />
      <Route path="/formulaire_myanmar" element={<FormMyanmar />} />
      <Route path="/formulaire_madagascar" element={<FormMadagascar />} />
      <Route path="/formulaire_laos" element={<FormLaos />} />
      <Route path="/formulaire_koweit" element={<FormKoweit />} />
      <Route path="/formulaire_kenya" element={<FormKenya />} />
      <Route path="/formulaire_jordanie" element={<FormJordanie />} />
      <Route path="/formulaire_indonesie" element={<FormIndonesie />} />
      <Route path="/formulaire_guinee" element={<FormGuinee />} />
      <Route path="/formulaire_ethiopie" element={<FormEthiopie />} />
      <Route path="/formulaire_egypte" element={<FormEgypte />} />
      <Route path="/formulaire_dubai" element={<FormDubai />} />
      <Route path="/formulaire_djibouti" element={<FormDjibouti />} />
      <Route path="/formulaire_cote_d_ivoire" element={<FormCoteIvoire />} />
      <Route path="/formulaire_coree_du_sud" element={<FormCoreeDuSud />} />
        <Route path="/formulaire_cap_vert" element={<FormCapVert />} />
        <Route path="/formulaire_canada" element={<FormCanada />} />
        <Route path="/formulaire_inde" element={<FormInde />} />
        <Route path="/formulaire_arabie_saoudite" element={<FormArabieSaoudite />} />
        <Route path="/formulaire_australie" element={<FormAustralie />} />
        <Route path="/formulaire_bahrein" element={<FormBahrein />} />
        <Route path="/formulaire_benin" element={<FormBenin />} />
        <Route path="/formulaire_cambodge" element={<FormCambodge />} />
        <Route path="/formulaire_cameroun" element={<FormCameroun />} />

        <Route path="/classique_formulaire_arabie_saoudite" element={<ClassiqueFormArabieSaoudite />} />

        <Route path="/" element={<LoginScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/signup" element={<SignUpScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/retour" element={<MyTabs />} />
        <Route path="/parametres" element={<ParameterScreen />} />
        <Route path="/formulaire_creation_voyageur" element={<VoyageurInsert />} />
        <Route path="/voyageur_update/:id" element={<VoyageurUpdate />} />
        <Route path="/paiement" element={<AdresseFacturation />} />

        


      </Routes>
    </Router>
  );
}

export default App;
