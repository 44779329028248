import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormCameroun: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // Récupération des informations depuis le state
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  // Dates
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');

  // Liste et sélection de voyageurs
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');

  // État de formulaire
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Assurance
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // Chargement
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Pièces jointes (fichiers)
  const [billetAvionFile, setBilletAvionFile] = useState<File | null>(null);
  const [carnetVaccinationFile, setCarnetVaccinationFile] = useState<File | null>(null);
  const [acteNaissanceFile, setActeNaissanceFile] = useState<File | null>(null);
  const [justificatifDomicileFile, setJustificatifDomicileFile] = useState<File | null>(null);

  // Champs spécifiques aux affaires
  const [lettreInvitationFile, setLettreInvitationFile] = useState<File | null>(null);
  const [lettreMissionFile, setLettreMissionFile] = useState<File | null>(null);

  // Autres pièces financières / hébergement
  const [releveCompteFile, setReleveCompteFile] = useState<File | null>(null);
  const [fichePayeFile, setFichePayeFile] = useState<File | null>(null);
  const [reservationHotelFile, setReservationHotelFile] = useState<File | null>(null);
  const [livretFamilleFile, setLivretFamilleFile] = useState<File | null>(null);

  // Prévisualisation (optionnel, si vous souhaitez afficher un aperçu) :
  const [previewFiles, setPreviewFiles] = useState<{ [key: string]: string }>({});

  // Total
  const total = prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Formatage date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, ''); 
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);

        // Sélection automatique s’il n’y a qu’un seul voyageur
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Empêcher la fermeture de l’onglet sans confirmation
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Validation du formulaire
  const validateForm = () => {
    const errors: string[] = [];

    // Conversions de date
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    // Vérification date départ
    if (!dateDepart.trim()) {
      errors.push('Date de départ');
    } else if (departDate && (isNaN(departDate.getTime()) || departDate <= today)) {
      errors.push("La date de départ doit être postérieure à aujourd'hui.");
    }

    // Vérification date retour
    if (!dateSortie.trim()) {
      errors.push('Date de sortie');
    } else if (sortieDate && departDate && sortieDate <= departDate) {
      errors.push('La date de retour doit être postérieure à la date de départ.');
    }

    // Sélection voyageur
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');

    // Pièces obligatoires
    if (!billetAvionFile) errors.push('Billet d’avion aller-retour nominatif requis');
    if (!carnetVaccinationFile) errors.push('Carnet de vaccination fièvre jaune requis');
    if (!acteNaissanceFile) errors.push('Acte de naissance ou livret de famille requis');
    if (!justificatifDomicileFile) errors.push('Justificatif de domicile requis');

    // Si visa affaire
    if (title.toLowerCase().includes('affaire')) {
      if (!lettreInvitationFile) errors.push("Lettre d'invitation (affaires) requise");
      if (!lettreMissionFile) errors.push("Lettre de mission (affaires) requise");
    }

    // Documents financiers / hébergement (non forcément obligatoires dans tous les cas,
    // ajustez la validation selon vos besoins)
    // Exemples d'obligations éventuelles - à adapter :
    // if (!releveCompteFile) errors.push('Dernier relevé de compte bancaire requis');
    // if (!fichePayeFile) errors.push('Dernière fiche de paye requise');

    // Assurance
    if (!assuranceVisa) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    selectedVoyageur,
    billetAvionFile,
    carnetVaccinationFile,
    acteNaissanceFile,
    justificatifDomicileFile,
    lettreInvitationFile,
    lettreMissionFile,
    releveCompteFile,
    fichePayeFile,
    reservationHotelFile,
    livretFamilleFile,
    assuranceVisa,
    sliderValue,
  ]);

  // Gestion des uploads (création d’une fonction par champ, ou utilisation d’une fonction générique)
  const handleFileChange =
    (setter: React.Dispatch<React.SetStateAction<File | null>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        setter(file);

        // Optionnel : Prévisualisation
        if (file.type.startsWith('image/')) {
          const fileUrl = URL.createObjectURL(file);
          setPreviewFiles(prev => ({ ...prev, [file.name]: fileUrl }));
        } else if (file.type === 'application/pdf') {
          const fileUrl = URL.createObjectURL(file);
          setPreviewFiles(prev => ({ ...prev, [file.name]: fileUrl }));
        }
      }
    };

  // Bouton retour
  const handleBack = () => {
    if (
      window.confirm(
        'Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?'
      )
    ) {
      navigate(-1);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      // Construction du FormData
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId || '');
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));

      // Ajout des fichiers
      if (billetAvionFile) formData.append('billetAvionFile', billetAvionFile);
      if (carnetVaccinationFile) formData.append('carnetVaccinationFile', carnetVaccinationFile);
      if (acteNaissanceFile) formData.append('acteNaissanceFile', acteNaissanceFile);
      if (justificatifDomicileFile) {
        formData.append('justificatifDomicileFile', justificatifDomicileFile);
      }

      // Si affaires
      if (title.toLowerCase().includes('affaire')) {
        if (lettreInvitationFile) formData.append('lettreInvitationFile', lettreInvitationFile);
        if (lettreMissionFile) formData.append('lettreMissionFile', lettreMissionFile);
      }

      // Autres pièces
      if (releveCompteFile) formData.append('releveCompteFile', releveCompteFile);
      if (fichePayeFile) formData.append('fichePayeFile', fichePayeFile);
      if (reservationHotelFile) formData.append('reservationHotelFile', reservationHotelFile);
      if (livretFamilleFile) formData.append('livretFamilleFile', livretFamilleFile);

      // Envoi vers le backend
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setIsLoading(false);
      // Redirection en cas de succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            backgroundColor: '#fff',
            p: 3,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          {/* En-tête */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Dates */}
          <TextField
            label="Date de départ (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Billet d’avion */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Billet d’avion aller-retour nominatif :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setBilletAvionFile)} />
          </Box>

          {/* Carnet de vaccination fièvre jaune */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Carnet de vaccination fièvre jaune :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setCarnetVaccinationFile)} />
          </Box>

          {/* Acte de naissance ou livret de famille */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Acte de naissance ou livret de famille :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setActeNaissanceFile)} />
          </Box>

          {/* Justificatif de domicile */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Justificatif de domicile :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setJustificatifDomicileFile)} />
          </Box>

          {/* Si affaires : Lettres invitation / mission */}
          {title.toLowerCase().includes('affaire') && (
            <>
              <Box sx={{ my: 2 }}>
                <Typography variant="subtitle1">Lettre d’invitation (affaires) :</Typography>
                <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setLettreInvitationFile)} />
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="subtitle1">Lettre de mission (affaires) :</Typography>
                <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setLettreMissionFile)} />
              </Box>
            </>
          )}

          {/* Relevé de compte */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Dernier relevé de compte bancaire :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setReleveCompteFile)} />
          </Box>

          {/* Fiche de paye */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Dernière fiche de paye :</Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setFichePayeFile)} />
          </Box>

          {/* Réservation d’hôtel */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">
              Réservation d’hôtel ou certificat d’hébergement visé par la Mairie (facultatif selon votre cas) :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setReservationHotelFile)} />
          </Box>

          {/* Livret de famille (si époux/enfants de personne née au Cameroun) */}
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">
              Livret de famille (uniquement pour les époux/épouses et enfants des personnes nées au Cameroun) :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleFileChange(setLivretFamilleFile)} />
          </Box>

          {/* Module Assurance */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif du total */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormCameroun;
