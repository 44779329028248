import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Modal,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';

import modifieVoyageur from '../modifieVoyageurService';
import { Voyageurs } from '../models/Voyageurs';

// ----------------------------------------------------------------
// Composant DocumentOrPhotoPicker (intégré directement)
// ----------------------------------------------------------------

interface DocumentOrPhotoPickerProps {
  documentType: string;
  onDocumentSelected: (uri: string) => void;
  modeOptions: string[];
}

const DocumentOrPhotoPicker: React.FC<DocumentOrPhotoPickerProps> = ({
  documentType,
  onDocumentSelected,
  modeOptions,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  // Accepte images et PDF si 'selectPdf' est dans modeOptions, sinon seulement images.
  const acceptedTypes = modeOptions.includes('selectPdf')
    ? 'image/*,application/pdf'
    : 'image/*';

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
      setFileType(file.type);
      onDocumentSelected(fileURL);
    }
  };

  return (
    <Box>
      <Button variant="outlined" component="label">
        {documentType} Upload
        <input type="file" hidden accept={acceptedTypes} onChange={handleFileChange} />
      </Button>

      {preview && (
        <Box mt={2}>
          {fileType === 'application/pdf' ? (
            <Box sx={{ width: '100%', height: 500 }}>
              <iframe
                src={preview}
                style={{ width: '100%', height: '100%' }}
                title="aperçu pdf"
              />
            </Box>
          ) : (
            <img
              src={preview}
              alt="aperçu"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------
// Interface pour récupérer le voyageur depuis l'état de la route
// ----------------------------------------------------------------

interface LocationState {
  voyageur: Voyageurs;
}

// ----------------------------------------------------------------
// Composant principal VoyageurUpdate
// ----------------------------------------------------------------

const VoyageurUpdate: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState | undefined;
  const initialVoyageur = state?.voyageur || null;

  // On déclare TOUS les hooks en haut du composant
  const [voyageur, setVoyageur] = useState<Voyageurs | null>(initialVoyageur);

  // États de validation pour certains champs
  const [nomError, setNomError] = useState(false);
  const [prenomError, setPrenomError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);
  const [professionError, setProfessionError] = useState(false);
  const [etatCivilError, setEtatCivilError] = useState(false);

  // États pour la gestion de l'adresse
  const [adresse, setAdresse] = useState(voyageur?.adresse || '');
  const [codePostal, setCodePostal] = useState(voyageur?.cp || '');
  const [ville, setVille] = useState(voyageur?.ville || '');
  const [adresseIdentique, setAdresseIdentique] = useState(
    voyageur?.adresse ? 'non' : 'oui'
  );

  // États pour les photos/documents
  const [selectedPhotoUri, setSelectedPhotoUri] = useState<string | null>(
    voyageur?.url || null
  );
  const defaultPasseportUri = voyageur?.url
    ? voyageur.url.replace('photo.jpg', 'passeport.jpg')
    : '';
  const [selectedPasseportUri, setSelectedPasseportUri] = useState<string | null>(
    defaultPasseportUri || null
  );

  // État civil
  const [etatCivil, setEtatCivil] = useState(voyageur?.etat_civil || '');

  // État de chargement
  const [isLoading, setIsLoading] = useState(false);

  // États pour le Modal d'aperçu d'image
  const [modalVisible, setModalVisible] = useState(false);
  const [previewImageUri, setPreviewImageUri] = useState<string | null>(null);

  // Redirection si aucun voyageur n'est disponible
  useEffect(() => {
    if (!voyageur) {
      navigate(-1);
    }
  }, [voyageur, navigate]);

  // Validation du formulaire
  const validateForm = (): boolean => {
    let isValid = true;

    if (!voyageur?.nom.trim()) {
      setNomError(true);
      isValid = false;
    } else {
      setNomError(false);
    }

    if (!voyageur?.prenom.trim()) {
      setPrenomError(true);
      isValid = false;
    } else {
      setPrenomError(false);
    }

    if (!etatCivil.trim()) {
      setEtatCivilError(true);
      isValid = false;
    } else {
      setEtatCivilError(false);
    }

    // Vérification du téléphone : uniquement des chiffres autorisés
    const phoneRegex = /^\d+$/;
    if (!voyageur?.telephone.trim() || !phoneRegex.test(voyageur.telephone.trim())) {
      setTelephoneError(true);
      isValid = false;
    } else {
      setTelephoneError(false);
    }

    if (!voyageur?.profession.trim()) {
      setProfessionError(true);
      isValid = false;
    } else {
      setProfessionError(false);
    }

    if (adresseIdentique === 'non') {
      if (!adresse.trim() || !codePostal.trim() || !ville.trim()) {
        alert('Veuillez remplir correctement votre adresse, code postal et ville.');
        isValid = false;
      }
    }
    return isValid;
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    if (validateForm() && voyageur) {
      setIsLoading(true);
      try {
        const newPhotoUri =
          selectedPhotoUri !== voyageur.url ? selectedPhotoUri : null;
        const newPasseportUri =
          selectedPasseportUri !== defaultPasseportUri ? selectedPasseportUri : null;

        await modifieVoyageur(
          voyageur.id,
          voyageur.nom,
          voyageur.prenom,
          newPhotoUri,
          newPasseportUri,
          adresseIdentique === 'non' ? adresse : '',
          adresseIdentique === 'non' ? codePostal : '',
          adresseIdentique === 'non' ? ville : '',
          voyageur.telephone,
          voyageur.profession,
          etatCivil
        );
        setIsLoading(false);
        navigate(-1);
      } catch (error) {
        console.error('Erreur lors de la mise à jour :', error);
        setIsLoading(false);
      }
    }
  };

  // Gestion de l'ouverture/fermeture du Modal d'aperçu d'image
  const handleOpenModal = (uri: string) => {
    setPreviewImageUri(uri);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPreviewImageUri(null);
  };

  // Mise à jour des champs du voyageur
  const handleChangeVoyageurField = (field: keyof Voyageurs, value: string) => {
    setVoyageur((prev) => (prev ? { ...prev, [field]: value } : prev));
  };

  // Sélection des URI pour les photos
  const handleSelectPhotoUri = (uri: string) => {
    setSelectedPhotoUri(uri);
  };

  const handleSelectPasseportUri = (uri: string) => {
    setSelectedPasseportUri(uri);
  };

  return (
    <>
      {voyageur ? (
        <Box
          sx={{
            maxWidth: 600,
            mx: 'auto',
            p: { xs: 2, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* En-tête avec bouton de retour */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" component="h2" sx={{ ml: 1 }}>
              Mettre à jour le voyageur
            </Typography>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <TextField
                fullWidth
                label="Nom"
                value={voyageur.nom}
                error={nomError}
                helperText={nomError ? 'Veuillez remplir ce champ' : ''}
                onChange={(e) => handleChangeVoyageurField('nom', e.target.value)}
              />

              <TextField
                fullWidth
                label="Prénom"
                value={voyageur.prenom}
                error={prenomError}
                helperText={prenomError ? 'Veuillez remplir ce champ' : ''}
                onChange={(e) => handleChangeVoyageurField('prenom', e.target.value)}
              />

              {/* Photo d'identité */}
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Photo d'identité :
                </Typography>
                <DocumentOrPhotoPicker
                  documentType="Photo d'identité"
                  onDocumentSelected={handleSelectPhotoUri}
                  modeOptions={['photo', 'selectPhoto']}
                />
              </Box>

              {/* Passeport */}
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Passeport :
                </Typography>
                <DocumentOrPhotoPicker
                  documentType="Passeport"
                  onDocumentSelected={handleSelectPasseportUri}
                  modeOptions={['photo', 'selectPhoto', 'selectPdf']}
                />
              </Box>

              {/* Adresse identique ? */}
              <FormControl fullWidth>
                <InputLabel id="adresse-identique-label">
                  Adresse identique à celle du passeport ?
                </InputLabel>
                <Select
                  labelId="adresse-identique-label"
                  label="Adresse identique à celle du passeport ?"
                  value={adresseIdentique}
                  onChange={(e) => {
                    setAdresseIdentique(e.target.value);
                    if (e.target.value === 'oui') {
                      setAdresse('');
                      setCodePostal('');
                      setVille('');
                    }
                  }}
                >
                  <MenuItem value="oui">Oui</MenuItem>
                  <MenuItem value="non">Non</MenuItem>
                </Select>
              </FormControl>

              {/* Champs d'adresse si non identique */}
              {adresseIdentique === 'non' && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Adresse"
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Code Postal"
                      value={codePostal}
                      onChange={(e) => setCodePostal(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Ville"
                      value={ville}
                      onChange={(e) => setVille(e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Sélection de l'état civil */}
              <FormControl fullWidth error={etatCivilError}>
                <InputLabel id="etat-civil-label">État Civil</InputLabel>
                <Select
                  labelId="etat-civil-label"
                  label="État Civil"
                  value={etatCivil}
                  onChange={(e) => setEtatCivil(e.target.value)}
                >
                  <MenuItem value="célibataire">Célibataire</MenuItem>
                  <MenuItem value="marié">Marié(e)</MenuItem>
                  <MenuItem value="divorcé">Divorcé(e)</MenuItem>
                  <MenuItem value="veuf">Veuf/Veuve</MenuItem>
                </Select>
                {etatCivilError && (
                  <Typography variant="caption" color="error">
                    Veuillez sélectionner un état civil.
                  </Typography>
                )}
              </FormControl>

              {/* Téléphone */}
              <TextField
                fullWidth
                label="Téléphone"
                value={voyageur.telephone}
                error={telephoneError}
                helperText={
                  telephoneError
                    ? 'Veuillez entrer un numéro valide (chiffres uniquement).'
                    : ''
                }
                onChange={(e) =>
                  handleChangeVoyageurField('telephone', e.target.value)
                }
                inputProps={{ inputMode: 'numeric' }}
              />

              {/* Profession */}
              <TextField
                fullWidth
                label="Profession"
                value={voyageur.profession}
                error={professionError}
                helperText={professionError ? 'Veuillez remplir ce champ' : ''}
                onChange={(e) =>
                  handleChangeVoyageurField('profession', e.target.value)
                }
              />

              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Mettre à jour le voyageur
              </Button>
            </Box>
          )}
        </Box>
      ) : null}

      {/* Modal d'aperçu d'image (optionnel) */}
      <Modal open={modalVisible} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            maxWidth: '90vw',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton onClick={handleCloseModal} sx={{ alignSelf: 'flex-end', mb: 1 }}>
            <CloseIcon />
          </IconButton>
          {previewImageUri && (
            <Box
              component="img"
              src={previewImageUri}
              alt="aperçu"
              sx={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VoyageurUpdate;
