import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Collapse,
} from '@mui/material';

interface AssuranceVisaComponentProps {
  assuranceVisa: string;
  setAssuranceVisa: (value: string) => void;
  sliderValue: boolean;
  setSliderValue: (value: boolean) => void;
}

const AssuranceVisaComponent: React.FC<AssuranceVisaComponentProps> = ({
  assuranceVisa,
  setAssuranceVisa,
  sliderValue,
  setSliderValue,
}) => {
  const [showConditions, setShowConditions] = useState<boolean>(false);

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setAssuranceVisa(newValue);
      if (newValue === 'non') {
        setSliderValue(false);
      }
    }
  };

  const handleShowConditions = (e: React.MouseEvent) => {
    e.preventDefault();
    // Ajout d'un listener en phase de capture pour stopper le beforeunload du parent.
    const blockBeforeUnload = (event: BeforeUnloadEvent) => {
      event.stopImmediatePropagation();
    };
    window.addEventListener('beforeunload', blockBeforeUnload, true);
    setShowConditions(true);
    // Retire le listener après un court délai.
    setTimeout(() => {
      window.removeEventListener('beforeunload', blockBeforeUnload, true);
    }, 100);
  };

  return (
    <Box sx={{ mb: 3, p: { xs: 2, sm: 3 } }}>
      {/* Encart dépliable pour afficher les conditions */}
      <Collapse in={showConditions}>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 2,
            p: 2,
            mb: 2,
            backgroundColor: '#f9f9f9',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Conditions générales de vente
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'justify', whiteSpace: 'pre-line', mb: 2 }}
          >
            {`Formulaire fourni par Visa Monde et nécessairement rempli et retourné à Visa Monde par le Client lors de sa demande de Prestation. 
Ce formulaire peut être un Bon de Commande Visa si le Client a recours à Visa Monde pour une demande de visa, ou un Bon de Commande Légalisation, 
si le Client a recours à Visa Monde pour une demande de légalisation d’un document.

L’envoi du Bon de commande par le Client à Visa Monde vaut acceptation de l’offre de Prestation et engagement de la payer.

Dans tous les cas, le Client devra nécessairement remplir et signer un formulaire nommé Bon de Commande Visa en cas de demande de visa 
et un Bon de Commande Légalisation en cas de demande de légalisation.

Ces deux documents sont désignés ci-après sous la dénomination unique « Bon de Commande ».

Le Bon de Commande devra être remis à Visa Monde soit directement au guichet (lorsque la commande est faite au guichet), 
soit par courrier postal ou pli déposé par porteur.

Aucune demande de Prestation ne sera traitée sans remise de Bon de Commande intégralement rempli et dûment signé. 
Les informations renseignées par le Client sur le Bon de Commande déterminent la Prestation sollicitée par le Client 
et conditionnent la demande de visa ou de légalisation de documents que transmettra Visa Monde auprès des Autorités Compétentes.

Visa Monde constitue le dossier du Client uniquement au regard des informations reportées sur le Bon de Commande. 
En aucun cas, Visa Monde ne vérifie l’exactitude des informations reportées sur le Bon de Commande, quand bien même d’autres documents 
fournis par le Client viendraient les contredire.

Il appartient donc au Client de fournir des informations sincères, exactes et complètes. En cas de demande de visa, il doit notamment indiquer la date 
d’entrée et de sortie de l’ensemble des Etats qu’il traversera au cours de son voyage, y compris les Etats dans lesquels il ne ferait que transiter. 
Le Client doit s’assurer que l’identité renseignée sur le Bon de Commande est conforme à celle de son ou ses documents de voyage.

En cas de demande de légalisation de documents, le Client doit renseigner le Bon de commande et la date limite 
à laquelle il souhaite recevoir les documents légalisés.

La remise du Bon de commande par le Client à Visa Monde vaut acceptation de l’offre de Prestation, du tarif de la Prestation et des présentes conditions générales de ventes.

Le Client s’engage à communiquer des coordonnées à jour et à informer Visa Monde sans délai de tout changement survenu depuis l’envoi du Bon de Commande.`}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setShowConditions(false)}
            sx={{ display: 'block', mx: 'auto' }}
            type="button"
          >
            Fermer
          </Button>
        </Box>
      </Collapse>

      {/* Titre et description */}
      <Typography
        variant="h6"
        align="center"
        sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}
      >
        Assurance Visa (20€)
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: 'gray', mb: 2 }}
      >
        Garantie de remboursement en cas de non obtention du visa, de retard de délivrance… (voir tableau des garanties)
      </Typography>

      {/* Boutons de sélection Oui/Non */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
        <ToggleButtonGroup
          value={assuranceVisa}
          exclusive
          onChange={handleToggle}
          aria-label="Sélection Assurance Visa"
        >
          <ToggleButton
            value="oui"
            aria-label="Oui"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              '&.Mui-selected': {
                backgroundColor: '#3eab36',
                color: 'white',
              },
            }}
          >
            Oui
          </ToggleButton>
          <ToggleButton
            value="non"
            aria-label="Non"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              '&.Mui-selected': {
                backgroundColor: '#3eab36',
                color: 'white',
              },
            }}
          >
            Non
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Liens vers les documents */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Link
          href="https://visamonde.com/chapka/Tableau%20des%20garanties%20assurance%20visa.pdf"
          target="_blank"
          underline="hover"
          sx={{ display: 'block', mb: 1, fontSize: 16 }}
        >
          Voir tableau des garanties
        </Link>
        <Link
          href="https://visamonde.com/chapka/CGV%20ass%20visa.pdf"
          target="_blank"
          underline="hover"
          sx={{ display: 'block', mb: 1, fontSize: 16 }}
        >
          Voir les conditions générales
        </Link>
        <Link
          href="https://visamonde.com/chapka/IPID%20assurance%20visa.pdf"
          target="_blank"
          underline="hover"
          sx={{ display: 'block', mb: 1, fontSize: 16 }}
        >
          Voir l’IPID
        </Link>
      </Box>

      {/* Section Switch pour accepter les conditions */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          En sélectionnant oui, j'accepte les{' '}
          <Link
            component="button"
            type="button"
            variant="body1"
            onClick={handleShowConditions}
            sx={{ textDecoration: 'underline', color: '#0645AD' }}
          >
            conditions générales de ventes
          </Link>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            checked={sliderValue}
            onChange={(e) => setSliderValue(e.target.checked)}
            color="primary"
          />
          <Typography variant="body1" sx={{ ml: 1 }}>
            Oui
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AssuranceVisaComponent;
