import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { Voyageurs } from '../models/Voyageurs';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormBenin: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // États issus des paramètres ou initialisés à des valeurs par défaut
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  // Champs spécifiques au formulaire pour le Bénin
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [adresseResidence, setAdresseResidence] = useState<string>('');
  const [paysDestination, setPaysDestination] = useState<string>('');

  // Champs liés aux voyageurs et à l'assurance
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Calcul du total
  const total =
    prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Validation du formulaire
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de sortie');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!adresseResidence.trim()) errors.push("Adresse de résidence durant le séjour au Bénin");
    if (!paysDestination.trim()) errors.push("Pays de destination après le séjour au Bénin");
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [dateDepart, dateSortie, assuranceVisa, sliderValue, selectedVoyageur, adresseResidence, paysDestination]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Fonction pour formatter la date en ajoutant automatiquement des slashs (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Soumission du formulaire via axios et redirection après succès
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;
      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        assuranceVisa: assuranceVisa,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        adresseResidence: adresseResidence,
        paysDestination: paysDestination,
      };

      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsLoading(false);
      // Après soumission, on navigue vers /retour avec le state demandé
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error("Erreur lors de la validation de la demande:", error);
      setIsLoading(false);
    }
  };

  // Fonction vide si bouton désactivé
  const disabledPressHandler = () => {};

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          {/* En-tête du formulaire */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box component="img" src={urlFlag} alt="Flag" sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }} />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
          </Box>

          {/* Sélection du voyageur (si non en mode édition) */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(v => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Saisie des dates et informations spécifiques */}
          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de résidence durant le séjour au Bénin"
            placeholder="Adresse de résidence"
            value={adresseResidence}
            onChange={(e) => setAdresseResidence(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Pays de destination après le séjour au Bénin"
            placeholder="Pays de destination"
            value={paysDestination}
            onChange={(e) => setPaysDestination(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Module Assurance (exactement comme dans FormInde) */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif des frais */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de soumission */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormBenin;
