import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdresseFacture } from '../models/AdresseFacture';
import {
  insertAdresseFacturation,
  recupereAdressesFacturation,
  updateAdresseFacturation,
} from '../Services';

interface LocationState {
  bonId: number;
  urlPaiement: string;
}

const AdresseFacturation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Récupération du state passé via navigate (peut être null si l'utilisateur rafraîchit la page)
  const locationState = location.state as LocationState | null;

  // Utilisation de valeurs par défaut en cas d'absence de state
  const bonId = locationState?.bonId ?? Number(localStorage.getItem('bonId')) ?? 1;
  const urlPaiement =
    locationState?.urlPaiement ??
    localStorage.getItem('urlPaiement') ??
    'https://nodejs-appli.visamonde.com/paiement';

  // Sauvegarde dans le localStorage
  useEffect(() => {
    localStorage.setItem('bonId', bonId.toString());
    localStorage.setItem('urlPaiement', urlPaiement);
  }, [bonId, urlPaiement]);

  // Hooks pour les adresses et le formulaire
  const [adresses, setAdresses] = useState<AdresseFacture[]>([]);
  const [adresse, setAdresse] = useState<AdresseFacture>({
    id: 0,
    id_utilisateur: 0,
    nom: '',
    adresse_ligne1: '',
    adresse_ligne2: '',
    ville: '',
    code_postal: '',
    pays: '',
    telephone: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchAdressesFacturation = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const adressesFacturation = await recupereAdressesFacturation(user.id_utilisateur);
        if (adressesFacturation && adressesFacturation.length > 0) {
          console.log(adressesFacturation);
          setAdresses(adressesFacturation);
          setAdresse(adressesFacturation[0]);
        }
      }
    };

    fetchAdressesFacturation();
  }, []);

  const handleAdresseChange = (event: any) => {
    const adresseId = event.target.value;
    console.log('Adresse ID:', adresseId);
    const idNum = Number(adresseId);
    const nouvelleAdresse = adresses.find((adr) => adr.id === idNum);
    console.log('Nouvelle Adresse:', nouvelleAdresse);
    if (nouvelleAdresse) {
      setAdresse(nouvelleAdresse);
    }
  };

  const ajouterAdresse = () => {
    setAdresse({
      id: 0,
      id_utilisateur: 0,
      nom: '',
      adresse_ligne1: '',
      adresse_ligne2: '',
      ville: '',
      code_postal: '',
      pays: '',
      telephone: '',
    });
  };

  const validateForm = (): boolean => {
    return (
      adresse.nom.trim() !== '' &&
      adresse.adresse_ligne1.trim() !== '' &&
      adresse.ville.trim() !== '' &&
      adresse.code_postal.trim() !== '' &&
      adresse.pays.trim() !== '' &&
      adresse.telephone.trim() !== ''
    );
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert('Erreur : Veuillez remplir tous les champs obligatoires.');
      return;
    }
    console.log('Adresse de facturation:', adresse);

    try {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const idUtilisateur = user.id_utilisateur;

        if (adresse.id === 0) {
          await insertAdresseFacturation(idUtilisateur, bonId, adresse);
        } else {
          await updateAdresseFacturation(idUtilisateur, bonId, adresse);
        }

        // Affichage de la boite d'informations au lieu d'une redirection immédiate
        setIsFormSubmitted(true);
      }
    } catch (error) {
      console.error("Erreur lors de la soumission de l'adresse :", error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          width: '100%',
          maxWidth: 600,
          bgcolor: 'rgba(255,255,255,0.9)',
        }}
      >
        {/* En-tête avec le bouton "arrière" et le titre */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={() => navigate('/retour', { state: { tabValue: 2 } })}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ ml: 1 }}>
            Adresse de Facturation
          </Typography>
        </Box>

        {isFormSubmitted ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Votre demande a été enregistrée
            </Typography>
            <Typography variant="body1" gutterBottom>
              L'équipe de VisaMonde va vérifier l'éligibilité de votre demande et vous enverra sous les meilleurs délais un lien de paiement pour traiter votre demande.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate('/retour', { state: { tabValue: 2 } })}
              sx={{ mt: 2 }}
            >
              J'ai compris
            </Button>
          </Box>
        ) : (
          <>
            {adresses.length > 0 && (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="adresse-select-label">Choisir une adresse</InputLabel>
                <Select
                  labelId="adresse-select-label"
                  value={adresse.id !== 0 ? adresse.id.toString() : ''}
                  label="Choisir une adresse"
                  onChange={handleAdresseChange}
                >
                  {adresses.map((adr) => (
                    <MenuItem key={adr.id} value={adr.id.toString()}>
                      {adr.nom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              label="Nom ou raison social"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.nom}
              onChange={(e) => setAdresse({ ...adresse, nom: e.target.value })}
            />
            <TextField
              fullWidth
              label="Adresse Ligne 1"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.adresse_ligne1}
              onChange={(e) => setAdresse({ ...adresse, adresse_ligne1: e.target.value })}
            />
            <TextField
              fullWidth
              label="Adresse Ligne 2"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.adresse_ligne2}
              onChange={(e) => setAdresse({ ...adresse, adresse_ligne2: e.target.value })}
            />
            <TextField
              fullWidth
              label="Ville"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.ville}
              onChange={(e) => setAdresse({ ...adresse, ville: e.target.value })}
            />
            <TextField
              fullWidth
              label="Code Postal"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.code_postal}
              onChange={(e) => setAdresse({ ...adresse, code_postal: e.target.value })}
            />
            <TextField
              fullWidth
              label="Pays"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.pays}
              onChange={(e) => setAdresse({ ...adresse, pays: e.target.value })}
            />
            <TextField
              fullWidth
              label="Téléphone"
              variant="outlined"
              sx={{ mb: 2 }}
              value={adresse.telephone}
              onChange={(e) => setAdresse({ ...adresse, telephone: e.target.value })}
            />
            {adresses.length > 0 && (
              <Button
                variant="outlined"
                fullWidth
                onClick={ajouterAdresse}
                sx={{ mt: 1, mb: 2 }}
              >
                Créer une nouvelle adresse
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Valider
            </Button>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AdresseFacturation;
