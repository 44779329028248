import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormMyanmar: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // États généraux (issus des paramètres ou par défaut)
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  // Champs communs
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // Champs spécifiques au formulaire Myanmar
  const [packageTour, setPackageTour] = useState<string>('');
  const [agencyNamePhone, setAgencyNamePhone] = useState<string>('');
  const [billetAvionFile, setBilletAvionFile] = useState<File | null>(null);
  const [reservationHotelFile, setReservationHotelFile] = useState<File | null>(null);
  const [lettreInvitationEntrepriseFile, setLettreInvitationEntrepriseFile] = useState<File | null>(null);
  const [certificatEnregistrementSocieteFile, setCertificatEnregistrementSocieteFile] = useState<File | null>(null);

  const total = prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Validation du formulaire
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);
    const errors: string[] = [];

    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push('Date de départ');
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push('Date de retour');
      }
    } else if (dateSortie && !sortieDate) {
      errors.push('Date de retour');
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");
    if (!packageTour) errors.push('Voyagez-vous avec un « Package Tour » ?');
    if (packageTour === 'Oui' && !agencyNamePhone.trim())
      errors.push("Nom et numéro de téléphone de l’agence touristique");
    if (!billetAvionFile) errors.push("Billet d’avion aller-retour");
    if (!reservationHotelFile) errors.push("Réservation hôtel ou certificat d’hébergement");

    if (title.toLowerCase().includes('affaire')) {
      if (!lettreInvitationEntrepriseFile)
        errors.push("Lettre d'invitation de l'entreprise enregistrée au Myanmar");
      if (!certificatEnregistrementSocieteFile)
        errors.push("Certificat d'enregistrement de la société au Myanmar");
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    packageTour,
    agencyNamePhone,
    billetAvionFile,
    reservationHotelFile,
    lettreInvitationEntrepriseFile,
    certificatEnregistrementSocieteFile,
  ]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Gestion des uploads pour les documents
  const handleBilletAvionUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) setBilletAvionFile(file);
  };

  const handleReservationHotelUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) setReservationHotelFile(file);
  };

  const handleLettreInvitationEntrepriseUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) setLettreInvitationEntrepriseFile(file);
  };

  const handleCertificatEnregistrementSocieteUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) setCertificatEnregistrementSocieteFile(file);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Soumission du formulaire : envoi via axios et redirection
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId || '');
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));
      formData.append('packageTour', packageTour);
      formData.append('agencyNamePhone', agencyNamePhone);
      if (billetAvionFile) {
        formData.append('billetAvion', billetAvionFile);
      }
      if (reservationHotelFile) {
        formData.append('reservationHotel', reservationHotelFile);
      }
      if (title.toLowerCase().includes('affaire')) {
        if (lettreInvitationEntrepriseFile)
          formData.append('lettreInvitationEntreprise', lettreInvitationEntrepriseFile);
        if (certificatEnregistrementSocieteFile)
          formData.append('certificatEnregistrementSociete', certificatEnregistrementSocieteFile);
      }
      // Envoi vers le backend (adapter l’URL si nécessaire)
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsLoading(false);
      // Redirection après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Dates */}
          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Package Tour */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="package-tour-select-label">
              Voyagez-vous avec un « Package Tour » ?
            </InputLabel>
            <Select
              labelId="package-tour-select-label"
              id="package-tour-select"
              value={packageTour}
              label="Voyagez-vous avec un « Package Tour » ?"
              onChange={(e) => {
                setPackageTour(e.target.value);
                if (e.target.value !== 'Oui') setAgencyNamePhone('');
              }}
            >
              <MenuItem value="Oui">Oui</MenuItem>
              <MenuItem value="Non">Non</MenuItem>
            </Select>
          </FormControl>
          {packageTour === 'Oui' && (
            <TextField
              label="Nom et numéro de téléphone de l’agence touristique"
              placeholder="Nom et téléphone de l'agence"
              value={agencyNamePhone}
              onChange={(e) => setAgencyNamePhone(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}

          {/* Uploads pour documents */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Billet d’avion aller-retour :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleBilletAvionUpload} />
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Réservation hôtel ou certificat d’hébergement :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleReservationHotelUpload} />
          </Box>
          {title.toLowerCase().includes('affaire') && (
            <>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Lettre d'invitation de l'entreprise enregistrée au Myanmar :
                </Typography>
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={handleLettreInvitationEntrepriseUpload}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Certificat d'enregistrement de la société au Myanmar :
                </Typography>
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={handleCertificatEnregistrementSocieteUpload}
                />
              </Box>
            </>
          )}

          {/* Module Assurance (reprenant exactement la partie du FormInde) */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif des frais */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormMyanmar;
