import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdAdd, MdDelete, MdFlightTakeoff, MdCheckCircle } from 'react-icons/md';
import Header from '../components/Header';
import { Demandes } from '../models/Demandes';
import { recupererListeDemandes, supprimerDemandeService, payer } from '../Services';

const DemandesScreen: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  // Utilisation de la forme fonctionnelle pour être sûr d'avoir la dernière valeur
  const handleSelect = (id: number) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const fetchDemandes = async () => {
    try {
      const userJson = localStorage.getItem('user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const userId = user.id_utilisateur;
        const demandesData = await recupererListeDemandes(userId);
        setDemandes(demandesData);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
    }
  };

  useEffect(() => {
    fetchDemandes();
    // Réinitialiser la sélection au focus
    const handleFocus = () => {
      fetchDemandes();
      setSelectedIds([]);
    };
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, []);

  const accederFacture = (cheminDemande: any) => {
    const url = `https://visamonde.com/demandes/${cheminDemande}/pax.pdf`;
    window.open(url, '_blank');
  };

  const handlePayer = async () => {
    setIsLoading(true);
    try {
      const { urlPaiement, bonId } = await payer(selectedIds);
      navigate('/paiement', { state: { bonId, urlPaiement } });
    } catch (error) {
      console.error('Erreur lors du paiement:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDemandeConfirmation = (id: number) => {
    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");
    if (isConfirmed) {
      handleDeleteDemande(id);
    }
  };

  const handleDeleteDemande = async (id: number) => {
    setIsLoading(true);
    try {
      await supprimerDemandeService(id);
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
      fetchDemandes();
    } catch (error) {
      console.error('Erreur lors de la suppression de la demande:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Seules les demandes dont l'état de paiement n'est ni "payé" ni "facturé"
  // et dont l'état de visa n'est pas "en cours" ou "Vérification éligibilité" sont sélectionnables
  const handleCardClick = (item: Demandes) => {
    if (
      item.etat_paiement !== 'payé' &&
      item.etat_paiement !== 'facturé' &&
      item.etat_visa !== 'en cours' &&
      item.etat_visa !== 'Vérification éligibilité'
    ) {
      handleSelect(item.id!);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Header />

      {/* Bouton "Créer une demande" */}
      <Box
        sx={{
          width: { xs: '90%', md: '90%' },
          mx: 'auto',
          my: 2,
          textAlign: 'center',
          p: 2,
          backgroundColor: '#fff',
          borderRadius: 1,
          borderBottom: '1px solid #ddd',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<MdAdd size={24} />}
          onClick={() => navigate('/retour', { state: { tabValue: 0 } })}
        >
          Créer une demande
        </Button>
      </Box>

      {/* Zone d'instruction */}
      <Box
        sx={{
          width: { xs: '90%', md: '90%' },
          mx: 'auto',
          my: 2,
          p: 2,
          backgroundColor: '#fff',
          borderRadius: 1,
          borderBottom: '1px solid #ccc',
        }}
      >
        <Typography variant="body2" align="center">
          Pour payer, cliquer sur une ou plusieurs demande.
        </Typography>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ px: 2, pb: selectedIds.length > 0 ? '80px' : 0 }}>
          {demandes.map((item, index) => {
            const isSelected = selectedIds.includes(item.id!);
            let borderColor = '#ddd';
            if (item.alerte === 'oui') {
              borderColor = 'red';
            } else if (item.etat_visa === 'en cours') {
              borderColor = 'green';
            } else if (item.infosdemande) {
              borderColor = '#00FF00';
            }

            // Détermine si la demande ne doit pas être sélectionnée pour le paiement
            const isNonSelectable = item.etat_visa === 'en cours' || item.etat_visa === 'Vérification éligibilité';

            return (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Card
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    border: 5,
                    borderColor: isSelected ? '#004085' : borderColor,
                    borderRadius: 2,
                    backgroundColor: isSelected ? '#cce5ff' : '#fff',
                    position: 'relative',
                    cursor: isNonSelectable ? 'default' : 'pointer',
                  }}
                  onClick={() => handleCardClick(item)}
                >
                  <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                    <Typography variant="subtitle1">Dossier Nº{item.id}</Typography>
                    {item.urlpays && (
                      <CardMedia
                        component="img"
                        image={item.urlpays}
                        alt="Flag"
                        sx={{ width: 40, height: 25, my: 1, margin: 'auto' }}
                      />
                    )}
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {item.titre}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 1 }}>
                      <MdFlightTakeoff size={20} color="#333" />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {item.datedepart}
                      </Typography>
                    </Box>
                    {item.urlvoyageur && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                        <img
                          src={item.urlvoyageur}
                          alt="Avatar"
                          style={{ width: 80, height: 80, borderRadius: '50%' }}
                        />
                      </Box>
                    )}
                    <Typography variant="body1">{item.prenom}</Typography>
                    <Typography variant="body1">{item.nom}</Typography>

                    {item.etat_visa === 'en cours' && (
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'green' }}>
                        En cours de traitement
                      </Typography>
                    )}
                    {item.etat_visa === 'Vérification éligibilité' && (
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'green' }}>
                        Vérification éligibilité
                      </Typography>
                    )}

                    {item.assurancevisa === 'oui' ? (
                      <>
                        <Typography variant="body2">Assurance: 20€</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Prix Total: {((item.prixconsulat || 0) + (item.prixintervention || 0) + 20)}€
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Prix Total: {((item.prixconsulat || 0) + (item.prixintervention || 0))}€
                      </Typography>
                    )}

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>{item.infosdemande}</strong>
                    </Typography>
                    <Typography variant="body2">{item.etat_paiement}</Typography>

                    {item.etat_paiement === 'facturé' && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          accederFacture(item.chemin);
                        }}
                      >
                        Accéder à ma facture
                      </Button>
                    )}
                  </CardContent>
                  {item.etat_paiement !== 'payé' && item.etat_paiement !== 'facturé' && (
                    <IconButton
                      sx={{ position: 'absolute', right: 8, bottom: 8 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteDemandeConfirmation(item.id!);
                      }}
                    >
                      <MdDelete size={24} color="#FF0000" />
                    </IconButton>
                  )}
                  {isSelected && (
                    <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                      <MdCheckCircle size={24} color="#004085" />
                    </Box>
                  )}
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* Bouton "Accéder au paiement" affiché si au moins une demande est sélectionnée */}
      {selectedIds.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            p: 2,
            borderTop: '1px solid #ccc',
            textAlign: 'center',
            zIndex: 9999,
          }}
        >
          <Button variant="contained" color="success" onClick={handlePayer}>
            Accéder au paiement
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DemandesScreen;
