import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from '../Services'; // Fonction à créer pour envoyer l'email
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const isEmailValid = (email:any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlePasswordReset = async () => {
    if (!email.trim()) {
      alert("Erreur : Veuillez entrer votre adresse e-mail.");
      return;
    }

    if (!isEmailValid(email)) {
      alert("Erreur : Veuillez entrer une adresse e-mail valide.");
      return;
    }

    try {
      console.log(email);
      await sendPasswordResetEmail(email);
      alert("Succès : Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse.");
      navigate(-1); // Retour à la page précédente
    } catch (error) {
      alert("Erreur : La réinitialisation du mot de passe a échoué.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
      }}
    >
      <Paper
        elevation={5}
        sx={{
          width: '90%',
          maxWidth: 500,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Réinitialisation du mot de passe
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          autoComplete="email"
          type="email"
        />
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handlePasswordReset}
          sx={{ mt: 2 }}
        >
          Envoyer l'email
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={() => navigate(-1)}
          sx={{ mt: 2 }}
        >
          Annuler
        </Button>
      </Paper>
    </Box>
  );
};

export default ForgotPasswordScreen;
