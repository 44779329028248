import React, { useState, useEffect } from 'react';
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MdPeople, MdPublic, MdDescription } from 'react-icons/md';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisasScreen from '../screens/VisasScreen';
import DemandesScreen from '../screens/DemandesScreen';
import VoyageursScreen from '../screens/VoyageursScreen';
import MesVisasScreen from '../screens/MesVisasScreen';
import { useLocation } from 'react-router-dom';

// Définition des types pour le carousel
interface CarouselItem {
  url: string;
}

interface CarouselProps {
  carouselItems: CarouselItem[];
}

// Composant Carousel simple
const Carousel: React.FC<CarouselProps> = ({ carouselItems }) => {
  const [index, setIndex] = useState<number>(0);

  const next = () => {
    setIndex((prev) => (prev + 1) % carouselItems.length);
  };

  const prev = () => {
    setIndex((prev) => (prev - 1 + carouselItems.length) % carouselItems.length);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <IconButton onClick={prev} sx={{ position: 'absolute', left: 0 }}>
        <ArrowBackIcon />
      </IconButton>
      <img
        src={carouselItems[index].url}
        alt={`Slide ${index}`}
        style={{ maxWidth: '100%', maxHeight: '80vh' }}
      />
      <IconButton onClick={next} sx={{ position: 'absolute', right: 0 }}>
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};

// Simulation d'AsyncStorage avec localStorage
const asyncLocalStorage = {
  getItem: async (key: string) => {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem: async (key: string, value: string) => {
    localStorage.setItem(key, value);
    return Promise.resolve();
  },
  removeItem: async (key: string) => {
    localStorage.removeItem(key);
    return Promise.resolve();
  },
};

const MyTabs: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const location = useLocation();
  const [tabValue, setTabValue] = useState<number>(location.state?.tabValue || 0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const carouselItems: CarouselItem[] = [
    { url: 'https://beta.visamonde.com/tuto/1.png' },
    { url: 'https://beta.visamonde.com/tuto/2.png' },
    { url: 'https://beta.visamonde.com/tuto/3.png' },
    { url: 'https://beta.visamonde.com/tuto/4.png' },
    { url: 'https://beta.visamonde.com/tuto/5.png' },
  ];

  useEffect(() => {
    if (location.state && typeof location.state.tabValue === "number") {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);
  

  useEffect(() => {
    const checkIfModalShown = async () => {
      try {
        const hasShownModal = await asyncLocalStorage.getItem('hasShownModal');
        if (!hasShownModal) {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du modal :', error);
      }
    };

    checkIfModalShown();
    incrementLoginCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = async () => {
    try {
      await asyncLocalStorage.setItem('hasShownModal', 'true');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Erreur lors de la fermeture du modal :', error);
    }
  };

  const resetModalShown = async () => {
    try {
      await asyncLocalStorage.removeItem('hasShownModal');
      setIsModalVisible(true);
    } catch (error) {
      console.error('Erreur lors de la réinitialisation du modal :', error);
    }
  };

  const incrementLoginCount = async () => {
    try {
      const loginCountStr = await asyncLocalStorage.getItem('loginCount');
      const loginCount = loginCountStr ? parseInt(loginCountStr, 10) : 0;
      const newCount = loginCount + 1;
      await asyncLocalStorage.setItem('loginCount', newCount.toString());

      if (newCount >= 5) {
        await asyncLocalStorage.setItem('loginCount', '0'); // Réinitialisation du compteur
      }
    } catch (error) {
      console.error("Erreur lors de l'incrémentation du compteur de connexion :", error);
    }
  };

  // Sélection du composant à afficher selon l'onglet choisi
  let ScreenComponent: React.FC;
  switch (tabValue) {
    case 0:
      ScreenComponent = VisasScreen;
      break;
    case 1:
      ScreenComponent = VoyageursScreen;
      break;
    case 2:
      ScreenComponent = DemandesScreen;
      break;
    case 3:
      ScreenComponent = MesVisasScreen;
      break;
    default:
      ScreenComponent = VisasScreen;
      break;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Zone de contenu avec uniquement un padding vertical */}
      <Box sx={{ flexGrow: 1, pt: 2, pb: '80px', px: 0 }}>
        <ScreenComponent />
      </Box>

      {/* Navigation par onglets fixe en bas */}
      <BottomNavigation
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        showLabels
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '70px',
          zIndex: (theme) => theme.zIndex.appBar,
          backgroundColor: 'background.paper',
        }}
      >
        <BottomNavigationAction
          label="Destinations"
          icon={<MdPublic size={32} />}
        />
        <BottomNavigationAction
          label="Voyageurs"
          icon={<MdPeople size={32} />}
        />
        <BottomNavigationAction
          label="Demandes"
          icon={<MdDescription size={32} />}
        />
        <BottomNavigationAction
          label="Mes visas"
          icon={
            <img
              src="/passport.png"
              alt="Mes visas"
              style={{ width: 32, height: 32 }}
            />
          }
        />
      </BottomNavigation>

      {/* Modal tutoriel */}
      <Dialog fullScreen={fullScreen} open={isModalVisible} onClose={handleModalClose}>
        <DialogTitle>Tutoriel</DialogTitle>
        <DialogContent>
          <Carousel carouselItems={carouselItems} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyTabs;
