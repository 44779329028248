import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp, checkSociete } from '../Services'; // Ajout de checkSociete
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Groupe = {
  id: number;
  id_societe: number;
  nom: string;
};

const SignUpScreen = () => {
  const [nom, setNom] = useState('');
  const [isNomDisabled, setIsNomDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState('red');
  const [passwordHelperText, setPasswordHelperText] = useState(
    'Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.'
  );
  const [groupes, setGroupes] = useState<Groupe[]>([]);
  const [selectedGroupe, setSelectedGroupe] = useState<number | string>('');
  const [idSociete, setIdSociete] = useState<number | null>(null);

  const navigate = useNavigate();

  const isPasswordSecure = (password: string) => password.length >= 8;
  const isEmailValid = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneValid = (phone: string) => /^[0-9]+$/.test(phone);

  const getPasswordStrength = (password: string) => {
    const length = password.length;
    if (length >= 8) {
      setPasswordHelperText('Mot de passe fort');
      return 'green';
    }
    if (length >= 4) {
      setPasswordHelperText('Mot de passe moyen');
      return 'yellow';
    }
    setPasswordHelperText('Le mot de passe doit contenir au moins 8 caractères.');
    return 'red';
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setMotDePasse(password);
    setPasswordStrengthColor(getPasswordStrength(password));
  };

  // Réinitialise le nom et les groupes si l'utilisateur modifie l'email après auto-remplissage
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (isNomDisabled) {
      setNom('');
      setIsNomDisabled(false);
      setGroupes([]);
      setIdSociete(null);
      setSelectedGroupe('');
    }
  };

  // Vérifie le domaine de l'email et récupère les infos de la société et de ses groupes
  const handleCheckSociete = async () => {
    if (email && email.includes('@')) {
      const domain = email.split('@')[1];
      try {
        const response = await checkSociete(domain);
        console.log("Réponse du service checksociete :", response);
        if (response.societe) {
          setNom(response.societe.nom);
          setIsNomDisabled(true);
          setIdSociete(response.societe.id);
        }
        if (response.groupes && response.groupes.length > 0) {
          setGroupes(response.groupes);
          // Si un seul groupe, le sélectionner par défaut
          if (response.groupes.length === 1) {
            setSelectedGroupe(response.groupes[0].id);
          }
        }
      } catch (error) {
        console.error("Erreur lors du check de société", error);
      }
    }
  };

  const handleSignUp = async () => {
    // Vérification des champs obligatoires
    if (!nom.trim() || !email.trim() || !motDePasse.trim() || !telephone.trim()) {
      window.alert('Erreur: Veuillez remplir tous les champs.');
      return;
    }
    if (!isEmailValid(email)) {
      window.alert('Erreur: Veuillez entrer un email valide.');
      return;
    }
    if (email !== confirmEmail) {
      window.alert('Erreur: Les adresses email ne correspondent pas.');
      return;
    }
    if (!isPhoneValid(telephone)) {
      window.alert('Erreur: Veuillez entrer un numéro de téléphone valide.');
      return;
    }
    if (!isPasswordSecure(motDePasse)) {
      window.alert('Erreur: Le mot de passe doit contenir au moins 8 caractères.');
      return;
    }
    // Si une société a été détectée et des groupes sont disponibles, vérifier qu'un groupe est sélectionné
    if (idSociete && groupes.length > 0 && !selectedGroupe) {
      window.alert('Erreur: Veuillez sélectionner un groupe.');
      return;
    }
    try {
      console.log(nom, email, motDePasse, telephone, idSociete, selectedGroupe);
      await signUp({
        nom,
        email,
        motDePasse,
        telephone,
        idSociete: idSociete ? idSociete : undefined,
        idGroupe: selectedGroupe ? selectedGroupe : undefined,
      });
      window.alert('Succès: Inscription réussie. Vous pouvez vous connecter.');
      navigate(-1); // Retour à la page précédente
    } catch (error) {
      window.alert("Erreur: L'inscription a échoué, il existe déjà un compte utilisateur avec ce mail.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={6} sx={{ padding: 4, borderRadius: 2 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Création compte
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              label="Nom Prénom / Raison sociale"
              variant="outlined"
              margin="normal"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              disabled={isNomDisabled}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleCheckSociete}
              autoComplete="email"
            />
            {/* Champs de confirmation d'email (n'apparaît que si le premier email est renseigné) */}
            {email && (
              <TextField
                fullWidth
                label="Confirmer l'Email"
                variant="outlined"
                margin="normal"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                autoComplete="email"
              />
            )}
            {/* Affichage du select des groupes s'il y a des résultats */}
            {groupes.length > 0 && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="groupe-select-label">Groupe</InputLabel>
                <Select
                  labelId="groupe-select-label"
                  id="groupe-select"
                  value={selectedGroupe}
                  label="Groupe"
                  onChange={(e) => setSelectedGroupe(e.target.value)}
                >
                  {groupes.map((groupe) => (
                    <MenuItem key={groupe.id} value={groupe.id}>
                      {groupe.nom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              label="Téléphone"
              variant="outlined"
              margin="normal"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              type="tel"
            />
            <TextField
              fullWidth
              label="Mot de passe"
              variant="outlined"
              margin="normal"
              value={motDePasse}
              onChange={handlePasswordChange}
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {/* Indicateur de force du mot de passe */}
            <Box
              sx={{
                width: '50%',
                height: 10,
                borderRadius: 1,
                backgroundColor: passwordStrengthColor,
                mt: 1,
                mb: 1
              }}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {passwordHelperText}
            </Typography>
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 2, backgroundColor: 'green' }}
              onClick={handleSignUp}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 2, backgroundColor: 'gray' }}
              onClick={() => navigate(-1)}
            >
              Revenir en arrière
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUpScreen;
