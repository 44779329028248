// VoyageurInsert.tsx
import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import creationVoyageur from '../creationVoyageurService';

interface DocumentOrPhotoPickerProps {
  documentType: string;
  onDocumentSelected: (uri: string) => void;
  modeOptions: string[];
}

const DocumentOrPhotoPicker: React.FC<DocumentOrPhotoPickerProps> = ({
  documentType,
  onDocumentSelected,
  modeOptions,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  // Si le mode inclut le PDF, on accepte images et pdf, sinon seulement images.
  const acceptedTypes = modeOptions.includes('selectPdf') ? 'image/*,application/pdf' : 'image/*';

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
      setFileType(file.type);
      onDocumentSelected(fileURL);
    }
  };

  return (
    <Box>
      <Button variant="outlined" component="label">
        {documentType} Upload
        <input type="file" hidden accept={acceptedTypes} onChange={handleFileChange} />
      </Button>

      {preview && (
        <Box mt={2}>
          {fileType === 'application/pdf' ? (
            // Affichage du PDF dans un iframe
            <Box sx={{ width: '100%', height: 500 }}>
              <iframe
                src={preview}
                style={{ width: '100%', height: '100%' }}
                title="aperçu pdf"
              />
            </Box>
          ) : (
            // Affichage de l'image
            <img src={preview} alt="aperçu" style={{ maxWidth: '100%', maxHeight: '300px' }} />
          )}
        </Box>
      )}
    </Box>
  );
};

const VoyageurInsert: React.FC = () => {
  const navigate = useNavigate();

  // États pour les différents champs du formulaire
  const [nom, setNom] = useState<string>('');
  const [prenom, setPrenom] = useState<string>('');
  const [selectedPhotoUri, setSelectedPhotoUri] = useState<string | null>(null);
  const [selectedPasseportUri, setSelectedPasseportUri] = useState<string | null>(null);
  const [adresseIdentique, setAdresseIdentique] = useState<string>('');
  const [adresse, setAdresse] = useState<string>('');
  const [codePostal, setCodePostal] = useState<string>('');
  const [ville, setVille] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [profession, setProfession] = useState<string>('');
  const [etatCivil, setEtatCivil] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Vérification de la validité du formulaire à chaque changement d'état
  useEffect(() => {
    checkFormValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nom,
    prenom,
    selectedPhotoUri,
    selectedPasseportUri,
    adresse,
    codePostal,
    ville,
    adresseIdentique,
    telephone,
    profession,
    etatCivil,
  ]);

  const checkFormValidity = () => {
    const isNomValid = nom.trim() !== '';
    const isPrenomValid = prenom.trim() !== '';
    const isPhotoUriPresent = selectedPhotoUri !== null;
    const isPasseportUriPresent = selectedPasseportUri !== null;
    const isTelephoneValid = telephone.trim() !== '';
    const isProfessionValid = profession.trim() !== '';
    const isEtatCivilValid = etatCivil.trim() !== '';
    let isAdresseValid = true;
    let isCodePostalValid = true;
    let isVilleValid = true;

    if (adresseIdentique === 'non') {
      isAdresseValid = adresse.trim() !== '';
      isCodePostalValid = codePostal.trim() !== '';
      isVilleValid = ville.trim() !== '';
    }

    setIsFormValid(
      isNomValid &&
        isPrenomValid &&
        isPhotoUriPresent &&
        isPasseportUriPresent &&
        isAdresseValid &&
        isCodePostalValid &&
        isVilleValid &&
        isTelephoneValid &&
        isProfessionValid &&
        isEtatCivilValid
    );
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;
    setIsLoading(true);
    try {
      // Récupération de l'utilisateur depuis le localStorage
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        await creationVoyageur({
          nom,
          prenom,
          idUtilisateur: String(user.id_utilisateur),
          photoUri: selectedPhotoUri,
          passeportUri: selectedPasseportUri,
          adresse,
          codePostal,
          ville,
          telephone,
          profession,
          etatCivil,
        });
        setIsLoading(false);
        navigate('/retour', { state: { tabValue: 2 } })
      }
    } catch (error) {
      console.error('Erreur lors de l’envoi:', error);
      setIsLoading(false);
    }
  };

  const handleTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredText = e.target.value.replace(/[^0-9]/g, '');
    setTelephone(filteredText);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
      {/* Bouton flèche pour revenir en arrière */}
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom sx={{ ml: 1 }}>
          Ajouter un Voyageur
        </Typography>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="form" noValidate autoComplete="off">
          <TextField
            fullWidth
            label="Nom"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Prénom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            margin="normal"
          />

          <Box mt={2}>
            <Typography variant="subtitle1">Photo d'identité :</Typography>
            <DocumentOrPhotoPicker
              documentType="Photo d'identité"
              onDocumentSelected={(uri: string) => setSelectedPhotoUri(uri)}
              modeOptions={['photo', 'selectPhoto']}
            />
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle1">Passeport :</Typography>
            <DocumentOrPhotoPicker
              documentType="Passeport"
              onDocumentSelected={(uri: string) => setSelectedPasseportUri(uri)}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
          </Box>

          <FormControl fullWidth margin="normal">
            <InputLabel id="adresse-identique-label">
              Adresse identique à celle du passeport ?
            </InputLabel>
            <Select
              labelId="adresse-identique-label"
              value={adresseIdentique}
              label="Adresse identique à celle du passeport ?"
              onChange={(e) => setAdresseIdentique(e.target.value)}
            >
              <MenuItem value="oui">Oui</MenuItem>
              <MenuItem value="non">Non</MenuItem>
            </Select>
          </FormControl>

          {adresseIdentique === 'non' && (
            <>
              <TextField
                fullWidth
                label="Adresse"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Code Postal"
                value={codePostal}
                onChange={(e) => setCodePostal(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Ville"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
                margin="normal"
              />
            </>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel id="etat-civil-label">État Civil</InputLabel>
            <Select
              labelId="etat-civil-label"
              value={etatCivil}
              label="État Civil"
              onChange={(e) => setEtatCivil(e.target.value)}
            >
              <MenuItem value="célibataire">Célibataire</MenuItem>
              <MenuItem value="marié">Marié(e)</MenuItem>
              <MenuItem value="divorcé">Divorcé(e)</MenuItem>
              <MenuItem value="veuf">Veuf/Veuve</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Téléphone"
            value={telephone}
            onChange={handleTelephoneChange}
            margin="normal"
            inputProps={{ inputMode: 'numeric' }}
          />
          <TextField
            fullWidth
            label="Profession"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            margin="normal"
          />

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Ajouter le voyageur
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VoyageurInsert;
