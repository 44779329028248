import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Modal
} from '@mui/material';
import Header from '../components/Header';
import { Demandes } from '../models/Demandes';
import { recupererListeVisasUtilisateur } from '../Services';
import { MdFileDownload, MdShare } from 'react-icons/md';

interface OfflineVisa {
  fileName: string;
  pdfUrl: string;
}

const MesVisasScreen: React.FC = () => {
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [downloadedVisas, setDownloadedVisas] = useState<string[]>([]);
  const [offlineVisas, setOfflineVisas] = useState<OfflineVisa[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(navigator.onLine);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Récupère les demandes et les visas téléchargés depuis le localStorage
  const fetchDemandes = async () => {
    try {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const userId = user.id_utilisateur; // Assurez-vous que cette clé est correcte
        const demandesData = await recupererListeVisasUtilisateur(userId);
        setDemandes(demandesData);
        const downloadedVisasData = localStorage.getItem('@downloadedVisas');
        if (downloadedVisasData) {
          setDownloadedVisas(JSON.parse(downloadedVisasData));
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  // Récupère les visas « hors connexion » depuis le localStorage
  const fetchOfflineVisas = () => {
    const offlineVisasData = localStorage.getItem('@offlineVisas');
    if (offlineVisasData) {
      setOfflineVisas(JSON.parse(offlineVisasData));
    }
  };

  // Mise à jour de l'état de connectivité via les événements en ligne/hors ligne
  useEffect(() => {
    const handleOnline = () => setIsConnected(true);
    const handleOffline = () => setIsConnected(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Chargement initial des données
  useEffect(() => {
    fetchDemandes();
    fetchOfflineVisas();
  }, []);

  // Formatage d'une date (ex : "2025-02-18" → "18/02/2025")
  const formatDate = (dateString: string | null): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Télécharge le fichier PDF en utilisant fetch et crée un lien de téléchargement
  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      alert(
        'Téléchargement réussi. Votre visa est bien enregistré sur votre mobile et sera accessible même hors connexion.'
      );
      const updatedDownloadedVisas = [...downloadedVisas, fileName];
      setDownloadedVisas(updatedDownloadedVisas);
      localStorage.setItem('@downloadedVisas', JSON.stringify(updatedDownloadedVisas));

      // On simule le stockage hors connexion en sauvegardant également l'URL du PDF
      const newOfflineVisa: OfflineVisa = { fileName, pdfUrl: url };
      const updatedOfflineVisas = [...offlineVisas, newOfflineVisa];
      setOfflineVisas(updatedOfflineVisas);
      localStorage.setItem('@offlineVisas', JSON.stringify(updatedOfflineVisas));
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
      alert('Erreur de téléchargement: Une erreur est survenue lors du téléchargement du fichier.');
    }
  };

  // Partage le fichier via l'API Web Share (si disponible)
  const shareFile = async (fileName: string) => {
    const offlineVisa = offlineVisas.find((v) => v.fileName === fileName);
    const fileUrl = offlineVisa ? offlineVisa.pdfUrl : '';
    if (navigator.share && fileUrl) {
      try {
        await navigator.share({
          title: 'Visa',
          text: 'Voici mon visa',
          url: fileUrl,
        });
      } catch (error) {
        console.error('Erreur lors du partage du fichier:', error);
      }
    } else {
      alert("Le partage n'est pas supporté sur ce navigateur.");
    }
  };

  // Ouvre le PDF en ligne dans un nouvel onglet
  const handlePressCard = (chemin: string) => {
    const url = `https://common.visamonde.com/demandes/${chemin}/visa.pdf`;
    window.open(url, '_blank');
  };

  const closePdf = () => {
    setSelectedPdf(null);
    setIsModalVisible(false);
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        pb: 4
      }}
    >
      <Header />
      <Modal open={isModalVisible} onClose={closePdf}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {selectedPdf && (
            <iframe
              src={selectedPdf}
              title="Visa PDF"
              style={{ flex: 1, border: 'none' }}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="secondary" onClick={closePdf}>
              Fermer
            </Button>
          </Box>
        </Box>
      </Modal>
      {isConnected ? (
        demandes.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh',
              p: 2
            }}
          >
            <Typography variant="h6" align="center" color="white">
              Vous n'avez aucun visa traité actuellement.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ p: 2 }}>
            {demandes.map((item, index) => {
              const fileName = `${item.titre}_${item.prenom}_${item.nom}.pdf`;
              const isDownloaded = downloadedVisas.includes(fileName);
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                          src={item.urlflag}
                          alt="flag"
                          style={{
                            width: 70,
                            height: 40,
                            objectFit: 'contain',
                            marginTop: 10
                          }}
                        />
                      </Box>
                      <Typography variant="h6" align="center">
                        {item.titre}
                      </Typography>
                      <Typography align="center">{`${item.nom} ${item.prenom}`}</Typography>
                      <Typography align="center">{`Départ: ${item.datedepart}`}</Typography>
                      {item.datevalidite && (
                        <Typography align="center">
                          {`Valide du: ${formatDate(item.datevalidite)}`}
                        </Typography>
                      )}
                      {item.dateexpiration && (
                        <Typography align="center">
                          {`Au: ${formatDate(item.dateexpiration)}`}
                        </Typography>
                      )}
                      <Typography align="center">{item.infosvisa}</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <img
                          src={item.urlvoyageur}
                          alt="avatar"
                          style={{
                            width: 60,
                            height: 60,
                            borderRadius: '50%'
                          }}
                        />
                      </Box>
                    </CardContent>
                   
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <Button variant="outlined" onClick={() => handlePressCard(item.chemin)}>
                        Accéder à mon visa
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )
      ) : (
        <Grid container spacing={2} sx={{ p: 2 }}>
          {offlineVisas.map((visa, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedPdf(visa.pdfUrl);
                  setIsModalVisible(true);
                }}
              >
                <CardContent>
                  <Typography align="center">{visa.fileName}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MesVisasScreen;
