import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

//
// Définition du type des paramètres reçus via react-router
//
interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormTanzanie: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { title, prixconsulat, prixintervention, demandeId, url_flag, validite, informations } = state;
  const isEditMode = demandeId != null;

  // États de base du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<any[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Nouveaux champs spécifiques (formulaire Tanzanie)
  const [nomEmployeur, setNomEmployeur] = useState<string>('');
  const [nomHebergement, setNomHebergement] = useState<string>('');
  const [adresseHebergement, setAdresseHebergement] = useState<string>('');
  const [codePostalHebergement, setCodePostalHebergement] = useState<string>('');
  const [villeHebergement, setVilleHebergement] = useState<string>('');
  const [billetAvionFile, setBilletAvionFile] = useState<File | null>(null);
  const [billetAvionPreview, setBilletAvionPreview] = useState<string | null>(null);

  // Pour les visas « affaire » : lettre d’invitation
  const [letterInvitationFile, setLetterInvitationFile] = useState<File | null>(null);
  const [letterInvitationPreview, setLetterInvitationPreview] = useState<string | null>(null);
  const [uploadedFileType, setUploadedFileType] = useState<'image' | 'pdf' | null>(null);

  // Calcul du total
  const total =
    prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Formatage d'une date entrée (jj/mm/aaaa)
  const formatDate = (input: string): string => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Validation du formulaire
  const validateForm = () => {
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Vérification des dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    // Champs obligatoires
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    // Champs spécifiques
    if (!nomEmployeur.trim()) errors.push('Nom employeur');
    if (!nomHebergement.trim()) errors.push('Nom hébergement');
    if (!adresseHebergement.trim()) errors.push("Adresse de l’hébergement");
    if (!codePostalHebergement.trim()) errors.push("Code postal de l’hébergement");
    if (!villeHebergement.trim()) errors.push("Ville de l’hébergement");
    if (!billetAvionFile) errors.push("Billet d’avion aller-retour");

    // Si visa affaire, lettre d'invitation obligatoire
    if (title && title.toLowerCase().includes('affaire')) {
      if (!letterInvitationFile) {
        errors.push("Lettre d'invitation");
      }
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    nomEmployeur,
    nomHebergement,
    adresseHebergement,
    codePostalHebergement,
    villeHebergement,
    billetAvionFile,
    letterInvitationFile,
  ]);

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Prévenir la fermeture accidentelle de l’onglet
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Bouton de retour
  const handleBack = () => {
    if (window.confirm("Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?")) {
      navigate(-1);
    }
  };

  // Gestion de l’upload pour le billet d’avion
  const handleBilletAvionUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setBilletAvionFile(file);
      const fileUrl = URL.createObjectURL(file);
      setBilletAvionPreview(fileUrl);
    }
  };

  // Gestion de l’upload pour la lettre d’invitation (pour visa affaire)
  const handleLetterInvitationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setUploadedFileType('image');
      } else if (file.type === 'application/pdf') {
        setUploadedFileType('pdf');
      } else {
        setUploadedFileType(null);
      }
      setLetterInvitationFile(file);
      const fileUrl = URL.createObjectURL(file);
      setLetterInvitationPreview(fileUrl);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string = '';
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;

      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId);
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));
      formData.append('nomEmployeur', nomEmployeur);
      formData.append('nomHebergement', nomHebergement);
      formData.append('adresseHebergement', adresseHebergement);
      formData.append('codePostalHebergement', codePostalHebergement);
      formData.append('villeHebergement', villeHebergement);

      if (billetAvionFile) {
        formData.append('billetAvion', billetAvionFile);
      }
      if (title && title.toLowerCase().includes('affaire') && letterInvitationFile) {
        formData.append('lettreInvitation', letterInvitationFile);
      }

      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsLoading(false);
      // Navigation après soumission
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  // Fonction vide pour onClick quand le bouton est désactivé
  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {url_flag && (
              <Box
                component="img"
                src={url_flag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Nouveaux champs */}
          <TextField
            label="Nom de l'employeur"
            placeholder="Nom de l'employeur"
            value={nomEmployeur}
            onChange={(e) => setNomEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom de l'hébergement"
            placeholder="Nom de l'hébergement"
            value={nomHebergement}
            onChange={(e) => setNomHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de l’hébergement"
            placeholder="Adresse de l’hébergement"
            value={adresseHebergement}
            onChange={(e) => setAdresseHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal de l’hébergement"
            placeholder="Code postal de l’hébergement"
            value={codePostalHebergement}
            onChange={(e) => setCodePostalHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville de l’hébergement"
            placeholder="Ville de l’hébergement"
            value={villeHebergement}
            onChange={(e) => setVilleHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Upload du billet d’avion */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Billet d’avion aller-retour :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleBilletAvionUpload} />
            {billetAvionPreview && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Aperçu :</Typography>
                <Box
                  component="img"
                  src={billetAvionPreview}
                  alt="Billet d'avion"
                  sx={{ maxWidth: '100%', maxHeight: 300 }}
                />
              </Box>
            )}
          </Box>

          {/* Upload de la lettre d’invitation si visa affaire */}
          {title && title.toLowerCase().includes('affaire') && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Lettre d'invitation :
              </Typography>
              <input type="file" accept="image/*,application/pdf" onChange={handleLetterInvitationUpload} />
              {letterInvitationPreview && (
                <Box sx={{ mt: 2 }}>
                  {uploadedFileType === 'image' ? (
                    <Box
                      component="img"
                      src={letterInvitationPreview}
                      alt="Prévisualisation de la lettre d'invitation"
                      sx={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  ) : uploadedFileType === 'pdf' ? (
                    <object data={letterInvitationPreview} type="application/pdf" width="100%" height="500">
                      <p>
                        PDF :{' '}
                        <a href={letterInvitationPreview} target="_blank" rel="noopener noreferrer">
                          Voir le document
                        </a>
                      </p>
                    </object>
                  ) : null}
                </Box>
              )}
            </Box>
          )}

          {/* Module AssuranceVisaComponent */}
          <AssuranceVisaComponent
            assuranceVisa={assuranceVisa}
            setAssuranceVisa={setAssuranceVisa}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />

          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormTanzanie;
