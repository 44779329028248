import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormPapouasieNG: React.FC = () => {
  // Récupération des paramètres de la route
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { demandeId, title, prixconsulat, prixintervention, url_flag, validite, informations } = state;
  const isEditMode = demandeId != null;

  // États hérités de la route
  const [visaTitle] = useState<string>(title || '');
  const [prixConsulat] = useState<number>(prixconsulat || 0);
  const [prixIntervention] = useState<number>(prixintervention || 0);
  const [urlFlag] = useState<string>(url_flag || '');
  const [visaValidite] = useState<string>(validite || '');
  const [visaInfos] = useState<string>(informations || '');

  // États communs
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // États spécifiques au formulaire Papouasie-NG
  const [adresseHebergement, setAdresseHebergement] = useState<string>('');
  const [codePostalHebergement, setCodePostalHebergement] = useState<string>('');
  const [villeHebergement, setVilleHebergement] = useState<string>('');
  const [dejaVisite, setDejaVisite] = useState<string>('');
  const [dateDerniereVisite, setDateDerniereVisite] = useState<string>('');
  const [dureeDerniereVisite, setDureeDerniereVisite] = useState<string>('');
  const [butDerniereVisite, setButDerniereVisite] = useState<string>('');

  // Gestion des fichiers
  const [lettreInvitationFile, setLettreInvitationFile] = useState<File | null>(null);
  const [releveBancaireFile, setReleveBancaireFile] = useState<File | null>(null);
  const [billetRetourFile, setBilletRetourFile] = useState<File | null>(null);
  const [lettreMotivationFile, setLettreMotivationFile] = useState<File | null>(null);

  // Pour prévisualisation des fichiers
  const [lettreInvitationPreview, setLettreInvitationPreview] = useState<string | null>(null);
  const [releveBancairePreview, setReleveBancairePreview] = useState<string | null>(null);
  const [billetRetourPreview, setBilletRetourPreview] = useState<string | null>(null);
  const [lettreMotivationPreview, setLettreMotivationPreview] = useState<string | null>(null);

  // Validation du formulaire
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Calcul du total
  const total = prixConsulat + prixIntervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Fonction utilitaire de conversion de date (jj/mm/aaaa)
  const parseDate = (dateStr: string): Date | null => {
    const parts = dateStr.split('/');
    if (parts.length === 3) {
      return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    }
    return null;
  };

  const validateForm = () => {
    const errors: string[] = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    // Contrôle des dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie) {
      if (sortieDate) {
        if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
          errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
        }
      } else {
        errors.push("La date de retour doit être au format valide.");
      }
    } else {
      errors.push("La date de retour est requise.");
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    if (!adresseHebergement.trim()) errors.push("Adresse de l'hébergement");
    if (!codePostalHebergement.trim()) errors.push("Code postal de l'hébergement");
    if (!villeHebergement.trim()) errors.push("Ville de l'hébergement");

    // Pour visa affaire, la lettre d'invitation est obligatoire
    if (visaTitle.toLowerCase().includes('affaire')) {
      if (!lettreInvitationFile) {
        errors.push("Lettre d'invitation");
      }
    }

    // Pour visa tourisme, les documents suivants sont obligatoires
    if (visaTitle.toLowerCase().includes('tourisme')) {
      if (!releveBancaireFile) errors.push("Relevé bancaire");
      if (!billetRetourFile) errors.push("Billet retour");
      if (!lettreMotivationFile) errors.push("Lettre de motivation");
    }

    if (!dejaVisite) errors.push("Avez-vous déjà visité la Papouasie-Nouvelle-Guinée ?");
    if (dejaVisite === 'Oui') {
      if (!dateDerniereVisite.trim()) errors.push("Date de la dernière visite");
      if (!dureeDerniereVisite.trim()) errors.push("Durée de la dernière visite");
      if (!butDerniereVisite.trim()) errors.push("But de la dernière visite");
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    adresseHebergement,
    codePostalHebergement,
    villeHebergement,
    lettreInvitationFile,
    releveBancaireFile,
    billetRetourFile,
    lettreMotivationFile,
    dejaVisite,
    dateDerniereVisite,
    dureeDerniereVisite,
    butDerniereVisite,
  ]);

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find((v) => v.id.toString() === selectedVoyageur);

  // Empêche la fermeture accidentelle de l’onglet
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const handleBack = () => {
    if (window.confirm("Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?")) {
      navigate(-1);
    }
  };

  // Gestion des uploads de fichiers (lettre d'invitation, relevé bancaire, billet retour, lettre de motivation)
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      if (type === 'lettreInvitation') {
        setLettreInvitationFile(file);
        setLettreInvitationPreview(preview);
      } else if (type === 'releveBancaire') {
        setReleveBancaireFile(file);
        setReleveBancairePreview(preview);
      } else if (type === 'billetRetour') {
        setBilletRetourFile(file);
        setBilletRetourPreview(preview);
      } else if (type === 'lettreMotivation') {
        setLettreMotivationFile(file);
        setLettreMotivationPreview(preview);
      }
    }
  };

  // Formatage de la date en jj/mm/aaaa
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) formatted += '/';
      formatted += numbers[i];
    }
    return formatted;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId = '';
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId);
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', visaTitle);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixConsulat));
      formData.append('prixintervention', String(prixIntervention));
      formData.append('adresseHebergement', adresseHebergement);
      formData.append('codePostalHebergement', codePostalHebergement);
      formData.append('villeHebergement', villeHebergement);
      formData.append('dejaVisite', dejaVisite);
      formData.append('dateDerniereVisite', dateDerniereVisite);
      formData.append('dureeDerniereVisite', dureeDerniereVisite);
      formData.append('butDerniereVisite', butDerniereVisite);

      // Ajout des fichiers selon le type de visa
      if (visaTitle.toLowerCase().includes('affaire') && lettreInvitationFile) {
        formData.append('lettreInvitation', lettreInvitationFile);
      }
      if (visaTitle.toLowerCase().includes('tourisme')) {
        if (releveBancaireFile) formData.append('releveBancaire', releveBancaireFile);
        if (billetRetourFile) formData.append('billetRetour', billetRetourFile);
        if (lettreMotivationFile) formData.append('lettreMotivation', lettreMotivationFile);
      }

      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsLoading(false);
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error("Erreur lors de la validation de la demande:", error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {visaTitle}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {visaValidite}
              <br />
              {visaInfos}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Champs hébergement */}
          <TextField
            label="Adresse de l’hébergement"
            placeholder="Adresse de l’hébergement"
            value={adresseHebergement}
            onChange={(e) => setAdresseHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal de l’hébergement"
            placeholder="Code postal de l’hébergement"
            value={codePostalHebergement}
            onChange={(e) => setCodePostalHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville de l’hébergement"
            placeholder="Ville de l’hébergement"
            value={villeHebergement}
            onChange={(e) => setVilleHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Avez-vous déjà visité la Papouasie-Nouvelle-Guinée ? */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="dejaVisite-label">
              Avez-vous déjà visité la Papouasie-Nouvelle-Guinée ?
            </InputLabel>
            <Select
              labelId="dejaVisite-label"
              id="dejaVisite-select"
              value={dejaVisite}
              label="Avez-vous déjà visité la Papouasie-Nouvelle-Guinée ?"
              onChange={(e: SelectChangeEvent) => setDejaVisite(e.target.value)}
            >
              <MenuItem value="Oui">Oui</MenuItem>
              <MenuItem value="Non">Non</MenuItem>
            </Select>
          </FormControl>

          {dejaVisite === 'Oui' && (
            <>
              <TextField
                label="Date de la dernière visite (jj/mm/aaaa)"
                placeholder="jj/mm/aaaa"
                value={dateDerniereVisite}
                onChange={(e) => setDateDerniereVisite(formatDate(e.target.value))}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Durée de la dernière visite"
                placeholder="Durée de la dernière visite"
                value={dureeDerniereVisite}
                onChange={(e) => setDureeDerniereVisite(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="But de la dernière visite"
                placeholder="But de la dernière visite"
                value={butDerniereVisite}
                onChange={(e) => setButDerniereVisite(e.target.value)}
                fullWidth
                margin="normal"
              />
            </>
          )}

          {/* Uploads de fichiers pour visa affaire */}
          {visaTitle.toLowerCase().includes('affaire') && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Lettre d'invitation :
              </Typography>
              <input type="file" accept="image/*,application/pdf" onChange={(e) => handleFileUpload(e, 'lettreInvitation')} />
              {lettreInvitationPreview && (
                <Box sx={{ mt: 2 }}>
                  <img
                    src={lettreInvitationPreview}
                    alt="Prévisualisation de la lettre d'invitation"
                    style={{ maxWidth: '100%', maxHeight: 300 }}
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Uploads de fichiers pour visa tourisme */}
          {visaTitle.toLowerCase().includes('tourisme') && (
            <>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Relevé bancaire :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={(e) => handleFileUpload(e, 'releveBancaire')} />
                {releveBancairePreview && (
                  <Box sx={{ mt: 2 }}>
                    <img
                      src={releveBancairePreview}
                      alt="Prévisualisation du relevé bancaire"
                      style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Billet retour :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={(e) => handleFileUpload(e, 'billetRetour')} />
                {billetRetourPreview && (
                  <Box sx={{ mt: 2 }}>
                    <img
                      src={billetRetourPreview}
                      alt="Prévisualisation du billet retour"
                      style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Lettre de motivation :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={(e) => handleFileUpload(e, 'lettreMotivation')} />
                {lettreMotivationPreview && (
                  <Box sx={{ mt: 2 }}>
                    <img
                      src={lettreMotivationPreview}
                      alt="Prévisualisation de la lettre de motivation"
                      style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}

          {/* Module Assurance (identique à FormInde) */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif du total */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixConsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixIntervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire */}
          <Box sx={{ my: 2, p: 2, borderRadius: 1, backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc' }}>
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormPapouasieNG;
