import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormOman: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { title, prixconsulat, prixintervention, url_flag, validite, informations, demandeId } = state;
  const isEditMode = demandeId != null;

  // États pour les champs du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Nouveau champ propre au formulaire FormOman
  const [nomPrenomMere, setNomPrenomMere] = useState<string>('');

  // Calcul du total
  const total =
    prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Validation du formulaire
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);
    const errors: string[] = [];

    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");
    if (!nomPrenomMere.trim()) errors.push("Nom et prénom de votre mère");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  // Revalidation dès qu'un champ pertinent change
  useEffect(() => {
    validateForm();
  }, [dateDepart, dateSortie, assuranceVisa, sliderValue, selectedVoyageur, nomPrenomMere]);

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find((v) => v.id.toString() === selectedVoyageur);

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Bouton retour
  const handleBack = () => {
    if (window.confirm("Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?")) {
      navigate(-1);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string = '';
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      // Construction de l'objet de données à envoyer
      const data = {
        etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart,
        dateSortie,
        assuranceVisa,
        prixconsulat,
        prixintervention,
        nomPrenomMere,
      };

      // Envoi vers le backend (URL à adapter si besoin)
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', data);
      setIsLoading(false);
      // Navigation après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {url_flag && (
              <Box
                component="img"
                src={url_flag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Nouveau champ propre à FormOman */}
          <TextField
            label="Nom et prénom de votre mère"
            placeholder="Nom et prénom de votre mère"
            value={nomPrenomMere}
            onChange={(e) => setNomPrenomMere(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Module AssuranceVisa tel que repris depuis FormInde */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* Indicateur de complétude du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : () => {}}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormOman;
