import axios from 'axios';

// Fonction pour déterminer le type MIME en fonction de l'extension du fichier
const getFileType = (uri) => {
  if (uri.startsWith('data:image/jpeg')) return 'image/jpeg';
  if (uri.startsWith('data:image/png')) return 'image/png';
  if (uri.startsWith('data:application/pdf')) return 'application/pdf';
  
  const extension = uri.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};

// Fonction pour convertir une data URI en Blob
const dataURItoBlob = (dataURI) => {
  const [header, base64Data] = dataURI.split(',');
  const isBase64 = header.indexOf('base64') >= 0;
  let byteString;
  if (isBase64) {
    byteString = atob(base64Data);
  } else {
    byteString = decodeURIComponent(base64Data);
  }
  const mimeString = header.split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const creationVoyageur = async (data) => {
  const formData = new FormData();

  // Ajouter les champs texte
  formData.append('nom', data.nom);
  formData.append('prenom', data.prenom);
  formData.append('idUtilisateur', data.idUtilisateur);
  formData.append('adresse', data.adresse);
  formData.append('codePostal', data.codePostal);
  formData.append('ville', data.ville);
  formData.append('profession', data.profession);
  formData.append('telephone', data.telephone);
  formData.append('etatCivil', data.etatCivil); // Ajout de l'état civil

  // Ajout de la photo
  if (data.photoUri.startsWith('data:')) {
    // Convertir la data URI en Blob
    const blob = dataURItoBlob(data.photoUri);
    let ext = '';
    if (blob.type === 'image/jpeg') {
      ext = 'jpg';
    } else if (blob.type === 'image/png') {
      ext = 'png';
    } else if (blob.type === 'application/pdf') {
      ext = 'pdf';
    } else {
      ext = 'bin';
    }
    formData.append('photo', blob, `photo.${ext}`);
  } else {
    // Si ce n'est pas une data URI, on suppose qu'il s'agit d'une URL accessible
    const response = await fetch(data.photoUri);
    const blob = await response.blob();
    let ext = '';
    if (blob.type === 'image/jpeg') {
      ext = 'jpg';
    } else if (blob.type === 'image/png') {
      ext = 'png';
    } else if (blob.type === 'application/pdf') {
      ext = 'pdf';
    } else {
      ext = 'bin';
    }
    formData.append('photo', blob, `photo.${ext}`);
  }

  // Ajout du passeport
  if (data.passeportUri.startsWith('data:')) {
    const blob = dataURItoBlob(data.passeportUri);
    let ext = '';
    if (blob.type === 'image/jpeg') {
      ext = 'jpg';
    } else if (blob.type === 'image/png') {
      ext = 'png';
    } else if (blob.type === 'application/pdf') {
      ext = 'pdf';
    } else {
      ext = 'bin';
    }
    formData.append('passeport', blob, `passeport.${ext}`);
  } else {
    const response = await fetch(data.passeportUri);
    const blob = await response.blob();
    let ext = '';
    if (blob.type === 'image/jpeg') {
      ext = 'jpg';
    } else if (blob.type === 'image/png') {
      ext = 'png';
    } else if (blob.type === 'application/pdf') {
      ext = 'pdf';
    } else {
      ext = 'bin';
    }
    formData.append('passeport', blob, `passeport.${ext}`);
  }

  try {
    const response = await axios.post(
      'https://nodejs-appli.visamonde.com/insertVoyageur',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log('Voyageur créé avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la création du voyageur:', error);
  }
};

export default creationVoyageur;
