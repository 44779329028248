// src/services/api.ts
import axios from 'axios';
import { Visas } from './models/Visas';
import { Voyageurs } from './models/Voyageurs';
import { Demandes } from './models/Demandes';
import { Pays } from './models/Pays';
import { AdresseFacture } from './models/AdresseFacture';

const BASE_URL = 'https://nodejs-appli.visamonde.com';

// Types pour les paramètres attendus par l'API
interface ValiderDemandeParams {
  idUtilisateur: number;
  idVoyageur: string;
  titreVisa: string;
  dateDepart: string;
  nomContact: string;
  prixconsulat: number;
  prixintervention: number;
}

interface SignUpParams {
  nom: string;
  email: string;
  motDePasse: string;
  telephone: string;
  idSociete?: number;
  idGroupe?: number | string;
}

// Envoi d'un email de réinitialisation du mot de passe
export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    const response = await axios.post(`${BASE_URL}/reset-password`, { email });
    console.log('Email de réinitialisation envoyé avec succès:', response.data);
  } catch (error) {
    console.error("Erreur lors de l'envoi de l'email de réinitialisation", error);
    throw new Error("Échec de l'envoi de l'email de réinitialisation");
  }
};

// Inscription d'un utilisateur
export const signUp = async ({ nom, email, motDePasse, telephone, idSociete, idGroupe }: SignUpParams) => {
  try {
    const response = await axios.post(`${BASE_URL}/utilisateurs/signUp`, {
      nom,
      email,
      motDePasse,
      telephone,
      idSociete,
      idGroupe,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'inscription:", error);
    throw error;
  }
};

// Suppression d'un compte utilisateur
export const deleteAccount = async (userMail: string): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/utilisateurs/${userMail}`);
    console.log('Compte supprimé avec succès');
  } catch (error) {
    console.error('Erreur lors de la suppression du compte', error);
    throw new Error("Erreur lors de la suppression du compte");
  }
};

// Récupération des factures d'un utilisateur (le backend renvoie du HTML)
export const recupereListeFactures = async (userMail: string): Promise<string> => {
  try {
    console.log(userMail);
    const response = await axios.get(`${BASE_URL}/factures`, {
      params: { email: userMail },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des factures', error);
    throw error;
  }
};

// Récupération des données d'une demande spécifique
export const recupereDonneesDemande = async (demandeId: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/recupereDonneesDemande/${demandeId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données de la demande', error);
    throw error;
  }
};

// Mise à jour d'une demande
export const updateDemande = async (data: any) => {
  try {
    const response = await axios.put(`${BASE_URL}/updateDemande`, data);
    console.log('Demande mise à jour avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la demande', error);
    throw error;
  }
};

// Insertion d'une adresse de facturation
export const insertAdresseFacturation = async (idUtilisateur: number, bonId: number, adresse: AdresseFacture) => {
  try {
    const response = await axios.post(`${BASE_URL}/adresseFacturation/insert`, {
      id_utilisateur: idUtilisateur,
      bon_id: bonId,
      nom: adresse.nom,
      adresse_ligne1: adresse.adresse_ligne1,
      adresse_ligne2: adresse.adresse_ligne2,
      ville: adresse.ville,
      code_postal: adresse.code_postal,
      pays: adresse.pays,
      telephone: adresse.telephone,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'insertion de l'adresse de facturation:", error);
    throw error;
  }
};

// Mise à jour d'une adresse de facturation
export const updateAdresseFacturation = async (idUtilisateur: number, bonId: number, adresse: AdresseFacture) => {
  try {
    const response = await axios.put(`${BASE_URL}/adresseFacturation/update`, {
      id: adresse.id,
      id_utilisateur: idUtilisateur,
      bon_id: bonId,
      nom: adresse.nom,
      adresse_ligne1: adresse.adresse_ligne1,
      adresse_ligne2: adresse.adresse_ligne2,
      ville: adresse.ville,
      code_postal: adresse.code_postal,
      pays: adresse.pays,
      telephone: adresse.telephone,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'adresse de facturation:", error);
    throw error;
  }
};

// Récupération des adresses de facturation d'un utilisateur
export const recupereAdressesFacturation = async (idUtilisateur: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/adressesfacturation/${idUtilisateur}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des adresses de facturation:", error);
    throw error;
  }
};

// Paiement d'une ou plusieurs demandes
export const payer = async (demandeIds: number[]): Promise<{ urlPaiement: string; bonId: number }> => {
  try {
    const response = await axios.post(`${BASE_URL}/payer`, { demandeIds });
    return {
      urlPaiement: response.data.urlPaiement,
      bonId: response.data.bonId,
    };
  } catch (error) {
    console.error('Erreur lors du paiement:', error);
    throw error;
  }
};

// Récupération de la liste des pays
export const recupererListePays = async (): Promise<Pays[]> => {
  try {
    const response = await axios.get<Pays[]>(`${BASE_URL}/pays`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des pays", error);
    return [];
  }
};

// Récupération de la liste des demandes d'un utilisateur
export const recupererListeDemandes = async (userId: number): Promise<Demandes[]> => {
  try {
    const response = await axios.get<Demandes[]>(`${BASE_URL}/demandes?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des demandes", error);
    return [];
  }
};

// Récupération de la liste des visas d'un utilisateur
export const recupererListeVisasUtilisateur = async (userId: number): Promise<Demandes[]> => {
  try {
    const response = await axios.get<Demandes[]>(`${BASE_URL}/visasutilisateur?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des demandes", error);
    return [];
  }
};

// Suppression d'une demande
export const supprimerDemandeService = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/demandes/${id}`);
    console.log(`Demande avec l'ID ${id} supprimée avec succès.`);
  } catch (error) {
    console.error(`Erreur lors de la suppression de la demande avec l'ID ${id}`, error);
    throw error;
  }
};

// Validation d'une demande avec gestion de fichiers (input type="file")
export const validerDemande = async (data: any): Promise<void> => {
  try {
    const formData = new FormData();

    for (const key in data) {
      const value = data[key];

      // Si value est un fichier, on l'ajoute directement avec son nom
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, value);
      }
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axios.post(`${BASE_URL}/validerDemande`, formData, config);
    console.log('Demande validée avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la validation de la demande:', error);
    throw error;
  }
};

// Connexion d'un utilisateur
export const login = async (email: string, password: string): Promise<any> => {
  try {
    const body = { email, password };
    const response = await axios.post(`${BASE_URL}/login`, body);
    console.log('Connexion réussie:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la tentative de connexion', error);
    throw error;
  }
};

// Récupération de la liste des visas
export async function recupererListeVisas(): Promise<Visas[]> {
  try {
    const response = await axios.get<Visas[]>(`${BASE_URL}/visas`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des visas", error);
    return [];
  }
}

// Mise à jour d'un voyageur
export const miseAJourVoyageur = async (id: number, nom: string, prenom: string): Promise<void> => {
  try {
    const body = { nom, prenom };
    const response = await axios.put(`${BASE_URL}/voyageurs/${id}`, body);
    console.log('Voyageur mis à jour avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour du voyageur', error);
    throw error;
  }
};

// Récupération de la liste des voyageurs d'un utilisateur
export const recupererListeVoyageurs = async (userId: number): Promise<Voyageurs[]> => {
  try {
    const response = await axios.get<Voyageurs[]>(`${BASE_URL}/voyageurs?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des voyageurs", error);
    return [];
  }
};

// Suppression d'un voyageur
export const supprimerVoyageurService = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/voyageurs/${id}`);
    console.log(`Voyageur avec l'ID ${id} supprimé avec succès.`);
  } catch (error) {
    console.error(`Erreur lors de la suppression du voyageur avec l'ID ${id}`, error);
    throw error;
  }
};

// Création d'un voyageur avec envoi de données de type FormData
export const creerVoyageur = async (formData: FormData): Promise<void> => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post(`${BASE_URL}/voyageurs`, formData, config);
    console.log('Voyageur créé avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la création du voyageur', error);
    throw error;
  }
};

// Nouveau service : Vérification de la société à partir du domaine de l'email
export const checkSociete = async (domain: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/checksociete`, { domain });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du check de société:", error);
    throw error;
  }
};