import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { recupererListeVoyageurs } from '../Services';
import axios from 'axios'; // <-- On va poster directement le FormData via axios
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormInde: React.FC = () => {
  // Récupération des paramètres passés en state via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // États initiaux (issus des paramètres de route ou valeurs par défaut)
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // États pour d’autres champs spécifiques
  const [lieuArrivee, setLieuArrivee] = useState<string>('');
  const [nomEmployeur, setNomEmployeur] = useState<string>('');
  const [adresseEmployeur, setAdresseEmployeur] = useState<string>('');
  const [codePostalEmployeur, setCodePostalEmployeur] = useState<string>('');
  const [villeEmployeur, setVilleEmployeur] = useState<string>('');
  const [telephoneEmployeur, setTelephoneEmployeur] = useState<string>('');
  const [nomSteInde, setNomSteInde] = useState<string>('');
  const [adresseSteInde, setAdresseSteInde] = useState<string>('');
  const [codePostalSteInde, setCodePostalSteInde] = useState<string>('');
  const [villeSteInde, setVilleSteInde] = useState<string>('');
  const [telephoneSteInde, setTelephoneSteInde] = useState<string>('');
  const [nomPrenomContactUrgence, setNomPrenomContactUrgence] = useState<string>('');
  const [adresseContact, setAdresseContact] = useState<string>('');
  const [codePostalContact, setCodePostalContact] = useState<string>('');
  const [villeContact, setVilleContact] = useState<string>('');
  const [telephoneContact, setTelephoneContact] = useState<string>('');

  // Pour la lettre d’invitation (fichier)
  const [letterInvitationFile, setLetterInvitationFile] = useState<File | null>(null);
  // Pour un éventuel aperçu local
  const [letterInvitationPreview, setLetterInvitationPreview] = useState<string | null>(null);
  const [uploadedFileType, setUploadedFileType] = useState<'image' | 'pdf' | null>(null);

  // Calcul du total
  const total =
    prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Validation du formulaire
  const validateForm = () => {
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Contrôles de dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    // Champs obligatoires
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de sortie');
    if (!lieuArrivee.trim()) errors.push('Lieu d’arrivée');
    if (!nomEmployeur.trim()) errors.push("Nom de l'employeur");
    if (!adresseEmployeur.trim()) errors.push("Adresse de l’employeur");
    if (!codePostalEmployeur.trim()) errors.push("Code postal de l’employeur");
    if (!villeEmployeur.trim()) errors.push("Ville de l’employeur");
    if (!telephoneEmployeur.trim()) errors.push("Téléphone de l’employeur");

    if (!nomPrenomContactUrgence.trim()) errors.push("Nom et prénom du contact en cas d'urgence");
    if (!adresseContact.trim()) errors.push("Adresse du contact");
    if (!codePostalContact.trim()) errors.push("Code postal du contact");
    if (!villeContact.trim()) errors.push("Ville du contact");
    if (!telephoneContact.trim()) errors.push("Téléphone du contact");

    // Si c’est un visa affaire
    if (title.toLowerCase().includes('affaire')) {
      // Fichier invitation obligatoire
      if (!letterInvitationFile) {
        errors.push("Lettre d'invitation");
      }
      // Les infos société en Inde
      if (!nomSteInde.trim()) errors.push("Nom de la sté en Inde");
      if (!adresseSteInde.trim()) errors.push("Adresse de la sté en Inde");
      if (!codePostalSteInde.trim()) errors.push("Code postal de la sté en Inde");
      if (!villeSteInde.trim()) errors.push("Ville de la sté en Inde");
      if (!telephoneSteInde.trim()) errors.push("Téléphone de la sté en Inde");
    }

    // Assurance
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  // Revalidation à chaque changement de champs
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    lieuArrivee,
    nomEmployeur,
    adresseEmployeur,
    codePostalEmployeur,
    villeEmployeur,
    telephoneEmployeur,
    nomSteInde,
    adresseSteInde,
    codePostalSteInde,
    villeSteInde,
    telephoneSteInde,
    nomPrenomContactUrgence,
    adresseContact,
    codePostalContact,
    villeContact,
    telephoneContact,
    letterInvitationFile,
  ]);

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        // Sélection automatique s’il n’y a qu’un seul voyageur
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Empêcher la fermeture de l’onglet sans confirmation
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Bouton retour
  const handleBack = () => {
    if (
      window.confirm(
        'Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?'
      )
    ) {
      navigate(-1);
    }
  };

  // Gestion de l’upload du fichier (lettre d’invitation) + prévisualisation
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setUploadedFileType('image');
      } else if (file.type === 'application/pdf') {
        setUploadedFileType('pdf');
      } else {
        setUploadedFileType(null);
      }
      // Stocke le File
      setLetterInvitationFile(file);

      // URL locale pour aperçu
      const fileUrl = URL.createObjectURL(file);
      setLetterInvitationPreview(fileUrl);
    }
  };

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Soumission
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      // Construction du FormData
      const formData = new FormData();
      // On y append tous les champs texte
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId || '');
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('lieuArrivee', lieuArrivee);
      formData.append('nomEmployeur', nomEmployeur);
      formData.append('adresseEmployeur', adresseEmployeur);
      formData.append('codePostalEmployeur', codePostalEmployeur);
      formData.append('villeEmployeur', villeEmployeur);
      formData.append('telephoneEmployeur', telephoneEmployeur);
      formData.append('nomPrenomContactUrgence', nomPrenomContactUrgence);
      formData.append('adresseContact', adresseContact);
      formData.append('codePostalContact', codePostalContact);
      formData.append('villeContact', villeContact);
      formData.append('telephoneContact', telephoneContact);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));

      // Si visa affaire => société Inde + lettre d’invitation
      if (title.toLowerCase().includes('affaire')) {
        formData.append('nomSteInde', nomSteInde);
        formData.append('adresseSteInde', adresseSteInde);
        formData.append('codePostalSteInde', codePostalSteInde);
        formData.append('villeSteInde', villeSteInde);
        formData.append('telephoneSteInde', telephoneSteInde);

        // Ajout du fichier
        if (letterInvitationFile) {
          formData.append('lettreInvitation', letterInvitationFile);
        }
      }

      // Envoi vers le backend (à adapter selon votre URL)
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setIsLoading(false);
      // Redirection après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  // Juste une fonction vide pour le onClick si disabled
  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            backgroundColor: '#fff',
            p: 3,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(v => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Upload du fichier lettre d’invitation (si visa affaire) */}
          {title.toLowerCase().includes('affaire') && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Lettre d'invitation :
              </Typography>
              <input type="file" accept="image/*,application/pdf" onChange={handleFileUpload} />
              {letterInvitationPreview && (
                <Box sx={{ mt: 2 }}>
                  {uploadedFileType === 'image' ? (
                    <img
                      src={letterInvitationPreview}
                      alt="Prévisualisation de la lettre d'invitation"
                      style={{ maxWidth: '100%', maxHeight: 300 }}
                    />
                  ) : uploadedFileType === 'pdf' ? (
                    <object
                      data={letterInvitationPreview}
                      type="application/pdf"
                      width="100%"
                      height="500"
                    >
                      <p>
                        PDF :{' '}
                        <a
                          href={letterInvitationPreview}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Voir le document
                        </a>
                      </p>
                    </object>
                  ) : null}
                </Box>
              )}
            </Box>
          )}

          <TextField
            label="Lieu d’arrivée"
            placeholder="Lieu d’arrivée"
            value={lieuArrivee}
            onChange={(e) => setLieuArrivee(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom de l'employeur"
            placeholder="Nom de l'employeur"
            value={nomEmployeur}
            onChange={(e) => setNomEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de l’employeur"
            placeholder="Adresse de l’employeur"
            value={adresseEmployeur}
            onChange={(e) => setAdresseEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal de l’employeur"
            placeholder="Code postal de l’employeur"
            value={codePostalEmployeur}
            onChange={(e) => setCodePostalEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville de l’employeur"
            placeholder="Ville de l’employeur"
            value={villeEmployeur}
            onChange={(e) => setVilleEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone de l’employeur"
            placeholder="Téléphone de l’employeur"
            value={telephoneEmployeur}
            onChange={(e) => setTelephoneEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Champs société en Inde (si visa affaire) */}
          {title.toLowerCase().includes('affaire') && (
            <>
              <TextField
                label="Nom de la sté en Inde"
                placeholder="Nom de la société en Inde"
                value={nomSteInde}
                onChange={(e) => setNomSteInde(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Adresse de la sté en Inde"
                placeholder="Adresse de la société en Inde"
                value={adresseSteInde}
                onChange={(e) => setAdresseSteInde(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Code postal de la sté en Inde"
                placeholder="Code postal de la société en Inde"
                value={codePostalSteInde}
                onChange={(e) => setCodePostalSteInde(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Ville de la sté en Inde"
                placeholder="Ville de la société en Inde"
                value={villeSteInde}
                onChange={(e) => setVilleSteInde(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Téléphone de la sté en Inde"
                placeholder="Téléphone de la société en Inde"
                value={telephoneSteInde}
                onChange={(e) => setTelephoneSteInde(e.target.value)}
                fullWidth
                margin="normal"
              />
            </>
          )}

          <TextField
            label="Nom et prénom du contact en cas d'urgence en France"
            placeholder="Nom et prénom du contact"
            value={nomPrenomContactUrgence}
            onChange={(e) => setNomPrenomContactUrgence(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse du contact"
            placeholder="Adresse du contact"
            value={adresseContact}
            onChange={(e) => setAdresseContact(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal du contact"
            placeholder="Code postal du contact"
            value={codePostalContact}
            onChange={(e) => setCodePostalContact(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville du contact"
            placeholder="Ville du contact"
            value={villeContact}
            onChange={(e) => setVilleContact(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone du contact"
            placeholder="Téléphone du contact"
            value={telephoneContact}
            onChange={(e) => setTelephoneContact(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Module Assurance */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif du total */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire (incomplet / complet) */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormInde;
