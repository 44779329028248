import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Modal,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  MdHelpOutline,
  MdChevronRight,
  MdInfoOutline,
  MdQuestionAnswer,
  MdArrowBack,
} from 'react-icons/md';
import { deleteAccount, recupereListeFactures } from '../Services';

interface CarouselItem {
  url: string;
}

const ParameterScreen: React.FC = () => {
  const [userName, setUserName] = useState<string>('');
  const [userMail, setUserMail] = useState<string>('');
  const [factures, setFactures] = useState<string>('');
  const [visas, setVisas] = useState<string[]>([]);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Exemple de carousel avec quelques URLs
  const carouselItems: CarouselItem[] = [
    {
      url: 'https://common.clicktoclub.com/files/0/74/pdf/IgTMOiH8-t0yKYM/ad_identity_photo.jpg?v=1717454325',
    },
    {
      url: 'https://common.clicktoclub.com/files/0/74/pdf/BVYsdfnsyqPtEw0/ad_identity_photo.jpeg?v=1717443950',
    },
    {
      url: 'https://common.clicktoclub.com/files/0/74/pdf/RJOKSQI5eXguR8J/ad_identity_photo.jpeg?v=1685196534',
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userJson = localStorage.getItem('user');
        if (userJson) {
          const user = JSON.parse(userJson);
          setUserName(user.nom);
          setUserMail(user.email);
          console.log("Appel de recupereListeFactures avec email:", user.email);
          const facturesHtml = await recupereListeFactures(user.email);
          console.log("Factures reçues:", facturesHtml);
          setFactures(facturesHtml);
        }
      } catch (error) {
        console.error('Failed to load user.', error);
      }
    };

    const loadVisas = async () => {
      try {
        // Sur le web, nous n'avons pas d'accès direct au système de fichiers comme avec expo-file-system.
        // Ici, nous simulons un tableau vide ou vous pourrez intégrer votre logique spécifique.
        setVisas([]);
      } catch (error) {
        console.error('Failed to load visas.', error);
      }
    };

    loadUser();
    loadVisas();
  }, []);

  const handleLogout = async () => {
    try {
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout.', error);
    }
  };

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      'Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.'
    );
    if (!confirmDelete) return;

    try {
      const storedMail = localStorage.getItem('mail');
      if (storedMail) {
        await deleteAccount(storedMail);
        localStorage.removeItem('user');
        localStorage.removeItem('mail');
        alert('Votre compte utilisateur a bien été supprimé.');
        navigate('/login');
      } else {
        alert("Impossible de récupérer les informations de l'utilisateur.");
      }
    } catch (error) {
      console.error('Failed to delete account.', error);
      alert("La suppression du compte a échoué.");
    }
  };

  const openPdf = (fileName: string) => {
    // Sur le web, vous pouvez gérer vos URLs PDF directement
    setSelectedPdf(fileName);
  };

  const closePdf = () => {
    setSelectedPdf(null);
  };

  const handleTutorialPress = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleContactPress = () => {
    window.open('https://beta.visamonde.com/#contact', '_blank');
  };

  const handleFaqPress = () => {
    window.open('https://beta.visamonde.com/FAQ.html', '_blank');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f8f8f8',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Bouton de retour en haut à gauche */}
      {!selectedPdf && (
        <Button
          onClick={() => navigate(-1)}
          sx={{
            position: 'fixed',
            top: 16,
            left: 16,
            zIndex: 1000,
            backgroundColor: 'black',
            color: 'white',
            '&:hover': { backgroundColor: '#333' },
            borderRadius: '50%',
            width: 48,
            height: 48,
            minWidth: 0,
            padding: 0,
          }}
        >
          <MdArrowBack size={24} />
        </Button>
      )}

      {selectedPdf ? (
        // Affichage du PDF via un iframe (vous pouvez intégrer une librairie dédiée si besoin)
        <Box sx={{ flexGrow: 1 }}>
          <iframe
            src={selectedPdf}
            title="PDF Viewer"
            style={{ width: '100%', height: 'calc(100vh - 50px)', border: 'none' }}
          />
          <Box sx={{ backgroundColor: 'black', p: 1, textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={closePdf}
              sx={{ backgroundColor: 'black' }}
            >
              Fermer
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {/* Header */}
          <Box sx={{ mt: 6, mb: 2, textAlign: 'center' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Bonjour, {userName}
            </Typography>
          </Box>

          {/* Contenu principal */}
          <Box
            sx={{
              flexGrow: 1,
              px: { xs: 2, sm: 3, md: 5 },
              overflowY: 'auto',
            }}
          >
            <Card sx={{ mb: 2, p: 2 }}>
              <CardContent>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 16, color: 'black', mb: 1 }}
                >
                  Liste de vos factures:
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: factures }} />
              </CardContent>
            </Card>
            {/* Vous pouvez ajouter ici un rendu de la liste des visas si nécessaire */}
          </Box>

          {/* Footer / Options */}
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
                cursor: 'pointer',
              }}
              onClick={handleTutorialPress}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MdHelpOutline size={24} color="#007AFF" />
                <Typography variant="body1" sx={{ ml: 1, color: 'black' }}>
                  Voir le tutoriel
                </Typography>
              </Box>
              <MdChevronRight size={24} color="#007AFF" />
            </Box>

            <Box
              sx={{
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
                cursor: 'pointer',
              }}
              onClick={handleContactPress}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MdInfoOutline size={24} color="#007AFF" />
                <Typography variant="body1" sx={{ ml: 1, color: 'black' }}>
                  Contactez-nous
                </Typography>
              </Box>
              <MdChevronRight size={24} color="#007AFF" />
            </Box>

            <Box
              sx={{
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
                cursor: 'pointer',
              }}
              onClick={handleFaqPress}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MdQuestionAnswer size={24} color="#007AFF" />
                <Typography variant="body1" sx={{ ml: 1, color: 'black' }}>
                  FAQ
                </Typography>
              </Box>
              <MdChevronRight size={24} color="#007AFF" />
            </Box>

            <Button
              variant="contained"
              fullWidth
              onClick={handleLogout}
              sx={{
                mb: 1,
                backgroundColor: 'black',
                py: 1.5,
                borderRadius: 2,
              }}
            >
              Déconnexion
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDeleteAccount}
              sx={{
                backgroundColor: 'black',
                py: 1.5,
                borderRadius: 2,
              }}
            >
              Supprimer mon compte
            </Button>
          </Box>
        </>
      )}

      {/* Modal pour le tutoriel (ici, un carousel simple) */}
      <CarouselModal
        open={isModalVisible}
        onClose={handleModalClose}
        items={carouselItems}
      />
    </Box>
  );
};

interface CarouselModalProps {
  open: boolean;
  onClose: () => void;
  items: CarouselItem[];
}

const CarouselModal: React.FC<CarouselModalProps> = ({ open, onClose, items }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 4,
          outline: 'none',
          borderRadius: 2,
          width: { xs: '90%', sm: '70%', md: '50%' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Button onClick={handlePrev}>Précédent</Button>
          <Button onClick={handleNext}>Suivant</Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src={items[currentIndex].url}
            alt={`Carousel ${currentIndex}`}
            sx={{ maxWidth: '100%', maxHeight: '60vh' }}
          />
        </Box>
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <Button onClick={onClose}>Fermer</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ParameterScreen;
