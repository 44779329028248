import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormJordanie: React.FC = () => {
  // Récupération des paramètres de la route
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // États issus des paramètres ou valeurs par défaut
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  // États du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  // Champs spécifiques au Jordan Pass
  const [hasJordanPass, setHasJordanPass] = useState<string>('');
  const [jordanPassNumber, setJordanPassNumber] = useState<string>('');

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Calcul du total (ajoute 20€ si l'assurance vaut "oui")
  const total = prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Fonction pour parser une date au format jj/mm/aaaa
  const parseDate = (dateStr: string): Date | null => {
    const parts = dateStr.split('/');
    if (parts.length === 3) {
      return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    }
    return null;
  };

  // Validation du formulaire avec contrôle sur la présence et la validité des dates
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Contrôle de la date de départ
    if (!dateDepart.trim()) {
      errors.push("La date de départ est requise.");
    } else if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est invalide.");
    }

    // Contrôle de la date de retour
    if (!dateSortie.trim()) {
      errors.push("La date de retour est requise.");
    } else if (sortieDate) {
      if (isNaN(sortieDate.getTime())) {
        errors.push("La date de retour doit être une date valide.");
      } else if (departDate && sortieDate <= departDate) {
        errors.push("La date de retour doit être postérieure à la date de départ.");
      }
    } else {
      errors.push("La date de retour est invalide.");
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!hasJordanPass) errors.push("Disposez-vous d'un Jordan Pass ?");
    if (hasJordanPass.toLowerCase() === 'oui' && !jordanPassNumber.trim()) {
      errors.push('Numéro du Jordan Pass');
    }
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push('Engagement sur les conditions générales de vente');

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [dateDepart, dateSortie, selectedVoyageur, hasJordanPass, jordanPassNumber, assuranceVisa, sliderValue]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find((v) => v.id.toString() === selectedVoyageur);

  // Empêche la fermeture accidentelle de l'onglet
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Bouton retour
  const handleBack = () => {
    if (window.confirm("Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?")) {
      navigate(-1);
    }
  };

  // Fonction de formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string): string => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;

      // Construction de l'objet de données
      const data = {
        etatFormulaire,
        idUtilisateur: userId || '',
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart,
        dateSortie,
        hasJordanPass,
        jordanPassNumber,
        assuranceVisa,
        prixconsulat,
        prixintervention,
      };

      // Envoi vers le backend (à adapter selon vos besoins)
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', data);
      setIsLoading(false);
      // Navigation après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Saisie des dates */}
          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Section Jordan Pass */}
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <FormLabel component="legend">Disposez-vous d'un Jordan Pass ?</FormLabel>
            <RadioGroup
              row
              value={hasJordanPass}
              onChange={(e) => setHasJordanPass(e.target.value)}
            >
              <FormControlLabel value="oui" control={<Radio />} label="Oui" />
              <FormControlLabel value="non" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>

          {hasJordanPass.toLowerCase() === 'oui' && (
            <TextField
              label="Numéro du Jordan Pass"
              placeholder="Numéro du Jordan Pass"
              value={jordanPassNumber}
              onChange={(e) => setJordanPassNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}

          {/* Module Assurance Visa */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif des frais */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* État du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de soumission */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : () => {}}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormJordanie;
