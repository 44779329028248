import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { recupererListeVoyageurs } from '../Services';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import OptionVisaComponent from '../components/OptionVisaComponent';
import validerDemande from '../validerFormulaire';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
}

const ClassiqueFormArabieSaoudite: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // États initiaux (issus des paramètres ou valeurs par défaut)
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(
    state?.prixconsulat || 0
  );
  const [prixintervention, setPrixintervention] = useState<number>(
    state?.prixintervention || 0
  );
  const urlFlag = state?.url_flag || '';

  // Champs du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<any[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');

  // Gestion assurance
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // Services optionnels (cases à cocher)
  const [optionalServices, setOptionalServices] = useState({
    enlevement: false,
    livraisonDHL: false,
    livraisonCoursier: false,
    traitementUrgence: false,
    formulaireRemplis: false,
  });

  // Liste des champs obligatoires manquants
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fonction pour calculer la somme des services optionnels
  const calculateOptionalServicesTotal = (): number => {
    let sum = 0;
    if (optionalServices.enlevement) sum += 10;
    if (optionalServices.livraisonDHL) sum += 12;
    if (optionalServices.livraisonCoursier) sum += 15;
    if (optionalServices.traitementUrgence) sum += 10;
    if (optionalServices.formulaireRemplis) sum += 20;
    return sum;
  };

  // Calcul du total
  // On additionne: frais consulat + frais service + assurance éventuelle + options
  const total =
    prixconsulat +
    prixintervention +
    (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0) +
    calculateOptionalServicesTotal();

  // Mise à jour des champs non remplis selon les valeurs saisies
  const updateChampsNonRemplis = () => {
    const champs: string[] = [];

    if (!selectedVoyageur) champs.push('Voyageur');
    if (!dateDepart.trim()) champs.push('Date de départ');
    if (!dateSortie.trim()) champs.push('Date de retour');
    if (!assuranceVisa.trim()) champs.push('Assurance Visa');
    if (!sliderValue) {
      champs.push('Engagement sur les conditions générales de vente');
    }
    // Les services optionnels ne sont pas obligatoires, donc pas de validation
    setChampsNonRemplis(champs);
  };

  // Vérification globale du formulaire
  const isFormValid = champsNonRemplis.length === 0;

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(
          user.id_utilisateur
        );
        setVoyageurs(listeVoyageurs);
        // Si un seul voyageur, on le sélectionne d'office
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  // Met à jour la liste des champs non remplis à chaque changement
  useEffect(() => {
    updateChampsNonRemplis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedVoyageur,
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    optionalServices,
  ]);

  // Récupération éventuelle du voyageur sélectionné pour l'afficher (avatar)
  const selectedVoyageurObj = voyageurs.find(
    (v: any) => v.id.toString() === selectedVoyageur
  );

  // Empêche de quitter la page sans confirmation
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string): string => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  const handleBack = () => {
    if (
      window.confirm(
        'Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?'
      )
    ) {
      navigate(-1);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = isFormValid;

      // Ajout : on modifie prixintervention en y ajoutant les services optionnels
      const prixInterventionAvecOptions =
        prixintervention + calculateOptionalServicesTotal();

      // On prépare les données à envoyer
      const data = {
        etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart,
        dateSortie,
        prixconsulat,
        // prixintervention devient la somme de la valeur initiale + options
        prixintervention: prixInterventionAvecOptions,
        assuranceVisa,
        optionalServices,
        optionalServicesTotal: calculateOptionalServicesTotal(),
      };

      await validerDemande(data);
      setIsLoading(false);
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {
    // Action si le formulaire est invalide
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          {/* En-tête */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur (si pas en mode édition) */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">
                Sélectionner un voyageur
              </InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((voyageur: any) => (
                  <MenuItem key={voyageur.id} value={voyageur.id.toString()}>
                    {voyageur.prenom} {voyageur.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={
                  selectedVoyageurObj.url ||
                  'https://example.com/default-avatar.png'
                }
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Dates départ / retour */}
          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Assurance */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Services optionnels */}
          <Box sx={{ my: 2 }}>
            <OptionVisaComponent
              optionalServices={optionalServices}
              setOptionalServices={setOptionalServices}
            />
          </Box>

          {/* Affichage des frais et total */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">
              Frais de visa : {prixconsulat}€
            </Typography>
            <Typography variant="body1">
              Frais de service : {prixintervention}€
            </Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* Indicateur de formulaire complet / incomplet */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor:
                champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ClassiqueFormArabieSaoudite;
