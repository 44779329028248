import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { recupererListeVoyageurs, validerDemande } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId?: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormAustralie: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { title, prixconsulat, prixintervention, url_flag, validite, informations, demandeId } = state;
  const isEditMode = demandeId != null;

  // États issus des paramètres ou initialisés par défaut
  const [titreVisa] = useState<string>(title || '');
  const [prixConsulat] = useState<number>(prixconsulat || 0);
  const [prixIntervention] = useState<number>(prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(url_flag || '');
  const [validiteVisa] = useState<string>(validite || '');
  const [informationsVisa] = useState<string>(informations || '');

  // États du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // Champs spécifiques à ce formulaire (basés sur FormAustralie en natif)
  const [previousVisa, setPreviousVisa] = useState<string>(''); // "oui" ou "non"
  const [visaNumber, setVisaNumber] = useState<string>('');
  const [casierVierge, setCasierVierge] = useState<string>(''); // Doit être "non"

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Calcul du total (frais de visa + frais de service + supplément assurance)
  const total = prixConsulat + prixIntervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Formatage de la date en jj/mm/aaaa
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Fonction de validation du formulaire
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);
    const errors: string[] = [];

    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de sortie');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    if (previousVisa === 'oui' && !visaNumber.trim()) {
      errors.push('Numéro de visa requis');
    }
    if (casierVierge !== 'oui') {
      errors.push("Déclaration de casier judiciaire vierge requise");
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  // Validation à chaque modification des champs
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDepart, dateSortie, assuranceVisa, sliderValue, selectedVoyageur, previousVisa, visaNumber, casierVierge]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Gestion de la sortie de la page pour prévenir l'utilisateur
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const handleBack = () => {
    if (window.confirm('Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?')) {
      navigate(-1);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;
      const data: any = {
        etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: titreVisa,
        dateDepart,
        dateSortie,
        previousVisa,
        visaNumber: previousVisa === 'oui' ? visaNumber : '',
        casierVierge,
        assuranceVisa,
        prixconsulat: prixConsulat,
        prixintervention: prixIntervention,
      };

      await validerDemande(data);
      setIsLoading(false);
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error("Erreur lors de la validation de la demande:", error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {titreVisa}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validiteVisa}
              <br />
              {informationsVisa}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(voyageur => (
                  <MenuItem key={voyageur.id} value={voyageur.id.toString()}>
                    {voyageur.nom} {voyageur.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Section visa antérieur */}
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="previous-visa-select-label">Avez-vous déjà eu un visa pour l'Australie ?</InputLabel>
            <Select
              labelId="previous-visa-select-label"
              id="previous-visa-select"
              value={previousVisa}
              label="Avez-vous déjà eu un visa pour l'Australie ?"
              onChange={(e: SelectChangeEvent) => setPreviousVisa(e.target.value)}
            >
              <MenuItem value="non">Non</MenuItem>
              <MenuItem value="oui">Oui</MenuItem>
            </Select>
          </FormControl>

          {previousVisa === 'oui' && (
            <TextField
              label="Numéro de visa"
              placeholder="Numéro de visa"
              value={visaNumber}
              onChange={(e) => setVisaNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="casier-select-label">Déclaration de casier judiciaire vierge</InputLabel>
            <Select
              labelId="casier-select-label"
              id="casier-select"
              value={casierVierge}
              label="Déclaration de casier judiciaire vierge"
              onChange={(e: SelectChangeEvent) => setCasierVierge(e.target.value)}
            >
              <MenuItem value="oui">Oui</MenuItem>
            </Select>
          </FormControl>

          {/* Assurance Visa identique à FormInde */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixConsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixIntervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormAustralie;
