import axios from 'axios';

// Fonction pour déterminer le type MIME en fonction de l'URI (data URI ou URL)
const getFileType = (uri) => {
  if (uri.startsWith('data:image/jpeg')) return 'image/jpeg';
  if (uri.startsWith('data:image/png')) return 'image/png';
  if (uri.startsWith('data:application/pdf')) return 'application/pdf';
  
  const extension = uri.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};

// Fonction pour convertir une data URI en Blob
const dataURItoBlob = (dataURI) => {
  const [header, base64Data] = dataURI.split(',');
  const isBase64 = header.indexOf('base64') >= 0;
  let byteString;
  if (isBase64) {
    byteString = atob(base64Data);
  } else {
    byteString = decodeURIComponent(base64Data);
  }
  const mimeString = header.split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const modifieVoyageur = async (
  idVoyageur,
  nom,
  prenom,
  newPhoto, // Chaîne URI (data URI ou URL accessible)
  newPax,   // Chaîne URI (data URI ou URL accessible)
  adresse,
  codePostal,
  ville,
  telephone,
  profession,
  etatCivil
) => {
  const formData = new FormData();
  formData.append('idVoyageur', idVoyageur);
  formData.append('nom', nom);
  formData.append('prenom', prenom);
  formData.append('adresse', adresse);
  formData.append('codePostal', codePostal);
  formData.append('ville', ville);
  formData.append('telephone', telephone);
  formData.append('profession', profession);
  formData.append('etatCivil', etatCivil);

  // Ajout de la photo si elle est fournie
  if (newPhoto) {
    if (newPhoto.startsWith('data:')) {
      // Conversion de la data URI en Blob
      const blob = dataURItoBlob(newPhoto);
      let ext = '';
      if (blob.type === 'image/jpeg') {
        ext = 'jpg';
      } else if (blob.type === 'image/png') {
        ext = 'png';
      } else if (blob.type === 'application/pdf') {
        ext = 'pdf';
      } else {
        ext = 'bin';
      }
      formData.append('photo', blob, `photo.${ext}`);
    } else {
      // On suppose que c'est une URL accessible
      const response = await fetch(newPhoto);
      const blob = await response.blob();
      let ext = '';
      if (blob.type === 'image/jpeg') {
        ext = 'jpg';
      } else if (blob.type === 'image/png') {
        ext = 'png';
      } else if (blob.type === 'application/pdf') {
        ext = 'pdf';
      } else {
        ext = 'bin';
      }
      formData.append('photo', blob, `photo.${ext}`);
    }
  }

  // Ajout du passeport s'il est fourni
  if (newPax) {
    if (newPax.startsWith('data:')) {
      const blob = dataURItoBlob(newPax);
      let ext = '';
      if (blob.type === 'image/jpeg') {
        ext = 'jpg';
      } else if (blob.type === 'image/png') {
        ext = 'png';
      } else if (blob.type === 'application/pdf') {
        ext = 'pdf';
      } else {
        ext = 'bin';
      }
      formData.append('passeport', blob, `passeport.${ext}`);
    } else {
      const response = await fetch(newPax);
      const blob = await response.blob();
      let ext = '';
      if (blob.type === 'image/jpeg') {
        ext = 'jpg';
      } else if (blob.type === 'image/png') {
        ext = 'png';
      } else if (blob.type === 'application/pdf') {
        ext = 'pdf';
      } else {
        ext = 'bin';
      }
      formData.append('passeport', blob, `passeport.${ext}`);
    }
  }

  try {
    const response = await axios.post(
      'https://nodejs-appli.visamonde.com/updateVoyageur',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log('Mise à jour réussie:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour du voyageur:', error);
  }
};

export default modifieVoyageur;
