import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { Voyageurs } from '../models/Voyageurs';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormKenya: React.FC = () => {
  // Récupération des paramètres passés via react-router (state)
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { title, prixconsulat, prixintervention, demandeId, url_flag, validite, informations } = state;
  const isEditMode = demandeId != null;

  // États pour les champs communs
  const [dateDepart, setDateDepart] = useState('');
  const [dateSortie, setDateSortie] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState('');
  const [sliderValue, setSliderValue] = useState(false);

  // États pour les fichiers (documents obligatoires)
  const [billetAvionFile, setBilletAvionFile] = useState<File | null>(null);
  const [billetAvionPreview, setBilletAvionPreview] = useState<string | null>(null);

  const [reservationHotelFile, setReservationHotelFile] = useState<File | null>(null);
  const [reservationHotelPreview, setReservationHotelPreview] = useState<string | null>(null);

  // Pour un visa affaire : lettre d’invitation, certificat d’enregistrement et carte d’identité accueil
  const [lettreInvitationFile, setLettreInvitationFile] = useState<File | null>(null);
  const [lettreInvitationPreview, setLettreInvitationPreview] = useState<string | null>(null);

  const [certificatEnregistrementFile, setCertificatEnregistrementFile] = useState<File | null>(null);
  const [certificatEnregistrementPreview, setCertificatEnregistrementPreview] = useState<string | null>(null);

  const [carteIdentiteAccueilFile, setCarteIdentiteAccueilFile] = useState<File | null>(null);
  const [carteIdentiteAccueilPreview, setCarteIdentiteAccueilPreview] = useState<string | null>(null);

  // Champs spécifiques pour le Kenya
  const [visitedKenya, setVisitedKenya] = useState('');
  const [eVisaNumber, setEVisaNumber] = useState('');
  const [lastVisitDate, setLastVisitDate] = useState('');
  const [lastVisitDuration, setLastVisitDuration] = useState('1 semaine');

  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Calcul du total (frais visa + service + assurance éventuelle)
  const total = prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Fonction de formatage d'une date au format jj/mm/aaaa
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Validation du formulaire
  const validateForm = () => {
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Vérifications sur les dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }
    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    // Champs obligatoires
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de retour');
    if (!billetAvionFile) errors.push("Billet d'avion non fourni");
    if (!reservationHotelFile) errors.push("Réservation d'hôtel non fournie");

    // Pour un visa affaire, champs et fichier complémentaire requis
    if (title.toLowerCase().includes('affaire')) {
      if (!lettreInvitationFile) errors.push("Lettre d'invitation non fournie");
      if (!certificatEnregistrementFile) errors.push("Certificat d'enregistrement non fourni");
      if (!carteIdentiteAccueilFile) errors.push("Carte d'identité de l'accueil non fournie");
    }

    // Champs liés à la visite du Kenya
    if (!visitedKenya) errors.push("Veuillez indiquer si vous avez déjà visité le Kenya");
    if (visitedKenya.toLowerCase() === 'oui') {
      if (!eVisaNumber.trim()) errors.push("N° Evisa est requis");
      if (!lastVisitDate.trim()) errors.push("Date de la dernière visite est requise");
      if (!lastVisitDuration) errors.push("Durée de la dernière visite est requise");
    }

    // Assurance Visa
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  // Revalidation à chaque modification de champs
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    selectedVoyageur,
    billetAvionFile,
    reservationHotelFile,
    lettreInvitationFile,
    certificatEnregistrementFile,
    carteIdentiteAccueilFile,
    visitedKenya,
    eVisaNumber,
    lastVisitDate,
    lastVisitDuration,
    assuranceVisa,
    sliderValue,
  ]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Gestion de l’upload des fichiers
  const handleBilletUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setBilletAvionFile(file);
      setBilletAvionPreview(URL.createObjectURL(file));
    }
  };

  const handleReservationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setReservationHotelFile(file);
      setReservationHotelPreview(URL.createObjectURL(file));
    }
  };

  const handleLettreInvitationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setLettreInvitationFile(file);
      setLettreInvitationPreview(URL.createObjectURL(file));
    }
  };

  const handleCertificatUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setCertificatEnregistrementFile(file);
      setCertificatEnregistrementPreview(URL.createObjectURL(file));
    }
  };

  const handleCarteIdentiteUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setCarteIdentiteAccueilFile(file);
      setCarteIdentiteAccueilPreview(URL.createObjectURL(file));
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId || '');
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));
      formData.append('assuranceVisa', assuranceVisa);

      // Ajout des fichiers
      if (billetAvionFile) formData.append('billetAvion', billetAvionFile);
      if (reservationHotelFile) formData.append('reservationHotel', reservationHotelFile);
      if (title.toLowerCase().includes('affaire')) {
        if (lettreInvitationFile) formData.append('lettreInvitation', lettreInvitationFile);
        if (certificatEnregistrementFile) formData.append('certificatEnregistrement', certificatEnregistrementFile);
        if (carteIdentiteAccueilFile) formData.append('carteIdentiteAccueil', carteIdentiteAccueilFile);
      }

      // Données concernant la visite du Kenya
      formData.append('visitedKenya', visitedKenya);
      if (visitedKenya.toLowerCase() === 'oui') {
        formData.append('eVisaNumber', eVisaNumber);
        formData.append('lastVisitDate', lastVisitDate);
        formData.append('lastVisitDuration', lastVisitDuration);
      }

      // Envoi vers le backend (URL à adapter si besoin)
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setIsLoading(false);
      // Redirection après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error("Erreur lors de la validation de la demande :", error);
      setIsLoading(false);
    }
  };

  // Fonction vide pour le onClick si désactivé
  const disabledPressHandler = () => {};

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {url_flag && (
              <Box
                component="img"
                src={url_flag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur (si non en mode édition) */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(v => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Fichiers – Billet d'avion et Réservation d'hôtel */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Billet d'avion :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleBilletUpload} />
            {billetAvionPreview && (
              <Box sx={{ mt: 2 }}>
                <img src={billetAvionPreview} alt="Prévisualisation du billet" style={{ maxWidth: '100%', maxHeight: 300 }} />
              </Box>
            )}
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Réservation d'hôtel :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleReservationUpload} />
            {reservationHotelPreview && (
              <Box sx={{ mt: 2 }}>
                <img src={reservationHotelPreview} alt="Prévisualisation de la réservation" style={{ maxWidth: '100%', maxHeight: 300 }} />
              </Box>
            )}
          </Box>

          {/* Pour visa affaire, documents supplémentaires */}
          {title.toLowerCase().includes('affaire') && (
            <>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Lettre d'invitation :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={handleLettreInvitationUpload} />
                {lettreInvitationPreview && (
                  <Box sx={{ mt: 2 }}>
                    <img src={lettreInvitationPreview} alt="Prévisualisation de la lettre" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  </Box>
                )}
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Certificat d'enregistrement :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={handleCertificatUpload} />
                {certificatEnregistrementPreview && (
                  <Box sx={{ mt: 2 }}>
                    <img src={certificatEnregistrementPreview} alt="Prévisualisation du certificat" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  </Box>
                )}
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Carte d'identité de la personne qui vous accueille :
                </Typography>
                <input type="file" accept="image/*,application/pdf" onChange={handleCarteIdentiteUpload} />
                {carteIdentiteAccueilPreview && (
                  <Box sx={{ mt: 2 }}>
                    <img src={carteIdentiteAccueilPreview} alt="Prévisualisation de la carte d'identité" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  </Box>
                )}
              </Box>
            </>
          )}

          {/* Champ de sélection sur la visite du Kenya */}
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="visited-kenya-label">Avez-vous déjà visité le Kenya ?</InputLabel>
            <Select
              labelId="visited-kenya-label"
              id="visited-kenya-select"
              value={visitedKenya}
              label="Avez-vous déjà visité le Kenya ?"
              onChange={(e) => setVisitedKenya(e.target.value)}
            >
              <MenuItem value="Oui">Oui</MenuItem>
              <MenuItem value="Non">Non</MenuItem>
            </Select>
          </FormControl>

          {visitedKenya.toLowerCase() === 'oui' && (
            <>
              <TextField
                label="N° Evisa"
                placeholder="N° Evisa"
                value={eVisaNumber}
                onChange={(e) => setEVisaNumber(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Date de la dernière visite (jj/mm/aaaa)"
                placeholder="jj/mm/aaaa"
                value={lastVisitDate}
                onChange={(e) => setLastVisitDate(formatDate(e.target.value))}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id="last-visit-duration-label">Durée de la dernière visite</InputLabel>
                <Select
                  labelId="last-visit-duration-label"
                  id="last-visit-duration-select"
                  value={lastVisitDuration}
                  label="Durée de la dernière visite"
                  onChange={(e) => setLastVisitDuration(e.target.value)}
                >
                  <MenuItem value="1 semaine">1 semaine</MenuItem>
                  <MenuItem value="2 semaines">2 semaines</MenuItem>
                  <MenuItem value="3 semaines">3 semaines</MenuItem>
                  <MenuItem value="1 mois">1 mois</MenuItem>
                  <MenuItem value="2 mois">2 mois</MenuItem>
                  <MenuItem value="3 mois">3 mois</MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          {/* Module Assurance Visa */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif des frais */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* Affichage de l'état du formulaire */}
          <Box sx={{ my: 2, p: 2, borderRadius: 1, backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc' }}>
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">Formulaire incomplet.</Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormKenya;
