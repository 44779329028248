import React from 'react';
import { Box, FormControlLabel, Checkbox, Typography } from '@mui/material';

interface OptionVisaComponentProps {
  optionalServices: {
    enlevement: boolean;
    livraisonDHL: boolean;
    livraisonCoursier: boolean;
    traitementUrgence: boolean;
    formulaireRemplis: boolean;
  };
  setOptionalServices: React.Dispatch<
    React.SetStateAction<{
      enlevement: boolean;
      livraisonDHL: boolean;
      livraisonCoursier: boolean;
      traitementUrgence: boolean;
      formulaireRemplis: boolean;
    }>
  >;
}

/**
 * Composant permettant de sélectionner différents services optionnels.
 * Chaque case à cocher modifie le state dans le parent (via setOptionalServices).
 */
const OptionVisaComponent: React.FC<OptionVisaComponentProps> = ({
  optionalServices,
  setOptionalServices,
}) => {
  // Les montants associés à chaque service
  const SERVICE_PRICES = {
    enlevement: 10,
    livraisonDHL: 12,
    livraisonCoursier: 15,
    traitementUrgence: 10,
    formulaireRemplis: 20,
  };

  // Fonction générique pour mettre à jour l'état lors du clic sur une checkbox
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionName: keyof typeof optionalServices
  ) => {
    setOptionalServices((prev) => ({
      ...prev,
      [optionName]: event.target.checked,
    }));
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 2,
        p: 2,
        mb: 2,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
        Services optionnels
      </Typography>

      {/* ENLEVEMENT */}
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalServices.enlevement}
            onChange={(e) => handleCheckboxChange(e, 'enlevement')}
          />
        }
        label={`Enlèvement (+${SERVICE_PRICES.enlevement}€)`}
      />

      {/* LIVRAISON DHL */}
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalServices.livraisonDHL}
            onChange={(e) => handleCheckboxChange(e, 'livraisonDHL')}
          />
        }
        label={`Livraison DHL (+${SERVICE_PRICES.livraisonDHL}€)`}
      />

      {/* LIVRAISON COURSIER */}
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalServices.livraisonCoursier}
            onChange={(e) => handleCheckboxChange(e, 'livraisonCoursier')}
          />
        }
        label={`Livraison coursier (+${SERVICE_PRICES.livraisonCoursier}€)`}
      />

      {/* TRAITEMENT URGENCE */}
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalServices.traitementUrgence}
            onChange={(e) => handleCheckboxChange(e, 'traitementUrgence')}
          />
        }
        label={`Traitement urgence (+${SERVICE_PRICES.traitementUrgence}€)`}
      />

      {/* FORMULAIRE REMPLI */}
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalServices.formulaireRemplis}
            onChange={(e) => handleCheckboxChange(e, 'formulaireRemplis')}
          />
        }
        label={`Formulaire rempli (+${SERVICE_PRICES.formulaireRemplis}€)`}
      />
    </Box>
  );
};

export default OptionVisaComponent;
