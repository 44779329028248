import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormTogo: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // Champs issus des paramètres de route
  const [title, setTitle] = useState<string>(state.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state.url_flag || '');
  const [validite, setValidite] = useState<string>(state.validite || '');
  const [informations, setInformations] = useState<string>(state.informations || '');

  // Champs du formulaire
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Champs spécifiques au formulaire Togo
  const [nomPrenomPere, setNomPrenomPere] = useState<string>('');
  const [nomPrenomMere, setNomPrenomMere] = useState<string>('');
  const [adresseTogo, setAdresseTogo] = useState<string>('');
  const [nomPrenomContact, setNomPrenomContact] = useState<string>('');
  const [telephoneContact, setTelephoneContact] = useState<string>('');
  const [mailContact, setMailContact] = useState<string>('');

  // Gestion des uploads de fichiers pour le billet d'avion
  const [billetAvionFile, setBilletAvionFile] = useState<File | null>(null);
  const [billetAvionPreview, setBilletAvionPreview] = useState<string | null>(null);
  const [billetAvionFileType, setBilletAvionFileType] = useState<'image' | 'pdf' | null>(null);

  // Pour le visa affaire, upload de la lettre de mission
  const [lettreMissionFile, setLettreMissionFile] = useState<File | null>(null);
  const [lettreMissionPreview, setLettreMissionPreview] = useState<string | null>(null);
  const [lettreMissionFileType, setLettreMissionFileType] = useState<'image' | 'pdf' | null>(null);

  // Calcul du total (frais de visa + service + assurance)
  const total = prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Fonction pour formater la date au format jj/mm/aaaa
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Validation du formulaire
  const validateForm = () => {
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Contrôles sur les dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }

    // Vérification des champs obligatoires
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de retour');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");
    if (!nomPrenomPere.trim()) errors.push("Nom et prénom du père");
    if (!nomPrenomMere.trim()) errors.push("Nom et prénom de la mère");
    if (!adresseTogo.trim()) errors.push("Adresse au Togo");
    if (!nomPrenomContact.trim()) errors.push("Nom et prénom du contact en cas d'urgence");
    if (!telephoneContact.trim()) errors.push("Téléphone du contact");
    if (!mailContact.trim()) errors.push("Mail du contact");
    if (!billetAvionFile) errors.push("Billet d'avion aller-retour");

    // Pour un visa affaire, la lettre de mission est requise
    if (title.toLowerCase().includes('affaire') && !lettreMissionFile) {
      errors.push("Lettre de mission de la société en France");
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    nomPrenomPere,
    nomPrenomMere,
    adresseTogo,
    nomPrenomContact,
    telephoneContact,
    mailContact,
    billetAvionFile,
    lettreMissionFile,
  ]);

  // Récupération de la liste des voyageurs (depuis le localStorage)
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  // Empêche la fermeture accidentelle de l’onglet
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Bouton retour avec confirmation
  const handleBack = () => {
    if (window.confirm("Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?")) {
      navigate(-1);
    }
  };

  // Gestion de l’upload pour le billet d’avion
  const handleBilletAvionUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setBilletAvionFileType('image');
      } else if (file.type === 'application/pdf') {
        setBilletAvionFileType('pdf');
      } else {
        setBilletAvionFileType(null);
      }
      setBilletAvionFile(file);
      const fileUrl = URL.createObjectURL(file);
      setBilletAvionPreview(fileUrl);
    }
  };

  // Gestion de l’upload pour la lettre de mission (visa affaire)
  const handleLettreMissionUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setLettreMissionFileType('image');
      } else if (file.type === 'application/pdf') {
        setLettreMissionFileType('pdf');
      } else {
        setLettreMissionFileType(null);
      }
      setLettreMissionFile(file);
      const fileUrl = URL.createObjectURL(file);
      setLettreMissionPreview(fileUrl);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId || '');
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', title);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixconsulat));
      formData.append('prixintervention', String(prixintervention));
      formData.append('nomPrenomPere', nomPrenomPere);
      formData.append('nomPrenomMere', nomPrenomMere);
      formData.append('adresseTogo', adresseTogo);
      formData.append('nomPrenomContact', nomPrenomContact);
      formData.append('telephoneContact', telephoneContact);
      formData.append('mailContact', mailContact);

      if (billetAvionFile) {
        formData.append('billetAvion', billetAvionFile);
      }
      if (title.toLowerCase().includes('affaire') && lettreMissionFile) {
        formData.append('lettreMission', lettreMissionFile);
      }

      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setIsLoading(false);
      // Après soumission, redirection vers '/retour' avec le state requis
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(v => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Upload du billet d'avion */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Billet d'avion aller-retour :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleBilletAvionUpload} />
            {billetAvionPreview && (
              <Box sx={{ mt: 2 }}>
                {billetAvionFileType === 'image' ? (
                  <img src={billetAvionPreview} alt="Prévisualisation du billet" style={{ maxWidth: '100%', maxHeight: 300 }} />
                ) : billetAvionFileType === 'pdf' ? (
                  <object data={billetAvionPreview} type="application/pdf" width="100%" height="500">
                    <p>
                      PDF : <a href={billetAvionPreview} target="_blank" rel="noopener noreferrer">Voir le document</a>
                    </p>
                  </object>
                ) : null}
              </Box>
            )}
          </Box>

          {/* Pour visa affaire, upload de la lettre de mission */}
          {title.toLowerCase().includes('affaire') && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Lettre de mission de la société en France :
              </Typography>
              <input type="file" accept="image/*,application/pdf" onChange={handleLettreMissionUpload} />
              {lettreMissionPreview && (
                <Box sx={{ mt: 2 }}>
                  {lettreMissionFileType === 'image' ? (
                    <img src={lettreMissionPreview} alt="Prévisualisation de la lettre de mission" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  ) : lettreMissionFileType === 'pdf' ? (
                    <object data={lettreMissionPreview} type="application/pdf" width="100%" height="500">
                      <p>
                        PDF : <a href={lettreMissionPreview} target="_blank" rel="noopener noreferrer">Voir le document</a>
                      </p>
                    </object>
                  ) : null}
                </Box>
              )}
            </Box>
          )}

          <TextField
            label="Nom et prénom du père"
            placeholder="Nom et prénom du père"
            value={nomPrenomPere}
            onChange={(e) => setNomPrenomPere(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom et prénom de la mère"
            placeholder="Nom et prénom de la mère"
            value={nomPrenomMere}
            onChange={(e) => setNomPrenomMere(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse au Togo"
            placeholder="Adresse au Togo"
            value={adresseTogo}
            onChange={(e) => setAdresseTogo(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom et prénom du contact en cas d'urgence"
            placeholder="Nom et prénom du contact"
            value={nomPrenomContact}
            onChange={(e) => setNomPrenomContact(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone du contact"
            placeholder="Téléphone du contact"
            value={telephoneContact}
            onChange={(e) => setTelephoneContact(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mail du contact"
            placeholder="Mail du contact"
            value={mailContact}
            onChange={(e) => setMailContact(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Module Assurance (identique à celui de FormInde) */}
          <AssuranceVisaComponent
            assuranceVisa={assuranceVisa}
            setAssuranceVisa={setAssuranceVisa}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />

          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          <Box sx={{ my: 2, p: 2, borderRadius: 1, backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc' }}>
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormTogo;
