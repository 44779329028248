// VoyageursScreen.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../components/Header';
import { recupererListeVoyageurs, supprimerVoyageurService } from '../Services';
import { Voyageurs } from '../models/Voyageurs';

const VoyageursScreen: React.FC = () => {
  const navigate = useNavigate();
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);

  // Récupère la liste des voyageurs et ajoute un paramètre "time" à l'URL de l'image pour forcer le rafraîchissement
  const fetchVoyageurs = async () => {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      const user = JSON.parse(userJson);
      const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
      const updatedVoyageurs = listeVoyageurs.map((voyageur: Voyageurs) => ({
        ...voyageur,
        url: voyageur.url ? `${voyageur.url}?time=${Date.now()}` : '',
      }));
      setVoyageurs(updatedVoyageurs);
    }
  };

  useEffect(() => {
    fetchVoyageurs();
  }, []);

  // Déclenche la suppression en utilisant window.confirm (vous pouvez également activer une boîte de dialogue MUI)
  const handleDelete = (id: number, nom: string, prenom: string, e: React.MouseEvent) => {
    // Empêche la propagation pour ne pas déclencher la navigation sur le clic de la carte
    e.stopPropagation();
    const message = `Êtes-vous sûr de vouloir supprimer le voyageur ${nom} ${prenom} ? 
Toute suppression entraînera la suppression des demandes et des visas liés à ce voyageur.`;
    if (window.confirm(message)) {
      supprimerVoyageurService(id)
        .then(() => fetchVoyageurs())
        .catch((error) => console.error("Erreur lors de la suppression du voyageur", error));
    }
  };

  const isVoyageurListEmpty = voyageurs.length === 0;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 2,
      }}
    >
      <Header />

      {isVoyageurListEmpty && (
        <Typography variant="h6" align="center" sx={{ color: 'white', mt: 2 }}>
          Aucun voyageur trouvé.
        </Typography>
      )}

      {/* Bouton "Créer un voyageur" */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/formulaire_creation_voyageur/')}
          sx={{
            width: '90%',
            py: 2,
            borderRadius: 2,
          }}
        >
          Créer un voyageur
        </Button>
      </Box>

      {/* Grille responsive pour afficher les cartes */}
      <Grid
        container
        spacing={2}
        justifyContent={voyageurs.length === 1 ? 'center' : 'flex-start'}
      >
        {voyageurs.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={voyageurs.length === 1 ? 8 : 6}
            md={(voyageurs.length === 1 || voyageurs.length === 2) ? 6 : 4}
          >
            <Card
              sx={{
                position: 'relative',
                cursor: 'pointer',
                borderRadius: 2,
                boxShadow: 3,
              }}
              onClick={() =>
                navigate(`/voyageur_update/${item.id}`, { state: { voyageur: item } })
              }
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h6">{item.prenom}</Typography>
                <Typography variant="h6">{item.nom}</Typography>
              </CardContent>
              <CardMedia
                component="img"
                image={item.url || '/assets/avatar.png'} // Assurez-vous que le chemin vers l'avatar par défaut est correct
                alt={`${item.nom} ${item.prenom}`}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: '50%',
                  objectFit: 'cover',
                  mx: 'auto',
                  mb: 2,
                }}
              />
              <CardContent sx={{ textAlign: 'center', pb: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  {item.numeropax}
                </Typography>
              </CardContent>
              <IconButton
                onClick={(e) => handleDelete(item.id, item.nom, item.prenom, e)}
                sx={{ position: 'absolute', bottom: 8, right: 8 }}
                aria-label="delete"
              >
                <DeleteIcon sx={{ color: 'red' }} />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VoyageursScreen;
