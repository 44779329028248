import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { MdMenu } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleSettings = () => {
    navigate('/parametres');
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        paddingTop: '30px',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          minHeight: '90px',
          px: '15px',
        }}
      >
        <Box
          component="img"
          src="/logoblanc.png" // Fichier dans le dossier public
          alt="Logo"
          sx={{
            width: { xs: '150px', sm: '200px' },
            height: { xs: '50px', sm: '70px' },
            objectFit: 'contain',
          }}
        />
        <IconButton onClick={handleSettings} sx={{ color: 'white' }}>
          <MdMenu size={40} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
