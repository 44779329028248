import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormUSA: React.FC = () => {
  // Récupération des paramètres passés en state via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const demandeId = state.demandeId;
  const isEditMode = demandeId != null;

  // Champs issus des paramètres de route
  const [title, setTitle] = useState<string>(state?.title || '');
  const [prixconsulat, setPrixconsulat] = useState<number>(state?.prixconsulat || 0);
  const [prixintervention, setPrixintervention] = useState<number>(state?.prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(state?.url_flag || '');
  const [validite, setValidite] = useState<string>(state?.validite || '');
  const [informations, setInformations] = useState<string>(state?.informations || '');

  // Champs communs
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [visiteUSA, setVisiteUSA] = useState<string>(''); // "oui" ou "non"
  const [numVisa, setNumVisa] = useState<string>('');
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);

  // Nouveaux champs
  const [nomPrenomPere, setNomPrenomPere] = useState<string>('');
  const [nomPrenomMere, setNomPrenomMere] = useState<string>('');
  const [nomPrenomContactUrgence, setNomPrenomContactUrgence] = useState<string>('');
  const [telephoneContactUrgence, setTelephoneContactUrgence] = useState<string>('');
  const [mailContactUrgence, setMailContactUrgence] = useState<string>('');
  const [adresseHebergement, setAdresseHebergement] = useState<string>('');
  const [codePostalHebergement, setCodePostalHebergement] = useState<string>('');
  const [villeHebergement, setVilleHebergement] = useState<string>('');
  const [telephoneHebergement, setTelephoneHebergement] = useState<string>('');
  const [mailHebergement, setMailHebergement] = useState<string>('');
  const [nomEmployeur, setNomEmployeur] = useState<string>('');
  const [adresseEmployeur, setAdresseEmployeur] = useState<string>('');
  const [codePostalEmployeur, setCodePostalEmployeur] = useState<string>('');
  const [villeEmployeur, setVilleEmployeur] = useState<string>('');
  const [telephoneEmployeur, setTelephoneEmployeur] = useState<string>('');
  const [mailEmployeur, setMailEmployeur] = useState<string>('');
  const [visitePaysInterdits, setVisitePaysInterdits] = useState<string>(''); // "oui" ou "non"
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Calcul du total
  const total =
    prixconsulat + prixintervention + (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Fonction de validation du formulaire
  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors: string[] = [];

    // Contrôle des dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }

    if (dateSortie) {
      if (sortieDate && departDate && (isNaN(sortieDate.getTime()) || sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      } else if (!sortieDate) {
        errors.push("La date de retour est requise et doit être au format valide.");
      }
    }

    // Validation des champs obligatoires
    if (!selectedVoyageur) errors.push("Voyageur non sélectionné");
    if (!dateDepart.trim()) errors.push("Date de départ");
    if (!dateSortie.trim()) errors.push("Date de retour");
    if (!visiteUSA.trim()) errors.push("Visite aux États-Unis");
    if (visiteUSA.toLowerCase() === 'oui' && !numVisa.trim())
      errors.push("Numéro du dernier visa/ESTA");
    if (!assuranceVisa.trim()) errors.push("Assurance Visa");
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    // Champs supplémentaires
    if (!nomPrenomPere.trim()) errors.push("Nom et prénom du père");
    if (!nomPrenomMere.trim()) errors.push("Nom et prénom de la mère");
    if (!nomPrenomContactUrgence.trim()) errors.push("Nom et prénom du contact en cas d'urgence");
    if (!telephoneContactUrgence.trim()) errors.push("Téléphone du contact");
    if (!mailContactUrgence.trim()) errors.push("Mail du contact");
    if (!adresseHebergement.trim()) errors.push("Adresse de l’hébergement");
    if (!codePostalHebergement.trim()) errors.push("Code postal de l’hébergement");
    if (!villeHebergement.trim()) errors.push("Ville de l’hébergement");
    if (!telephoneHebergement.trim()) errors.push("Téléphone de l’hébergement");
    if (!mailHebergement.trim()) errors.push("Mail de l’hébergement");
    if (!nomEmployeur.trim()) errors.push("Nom de l'employeur");
    if (!adresseEmployeur.trim()) errors.push("Adresse de l’employeur");
    if (!codePostalEmployeur.trim()) errors.push("Code postal de l’employeur");
    if (!villeEmployeur.trim()) errors.push("Ville de l’employeur");
    if (!telephoneEmployeur.trim()) errors.push("Téléphone de l’employeur");
    if (!mailEmployeur.trim()) errors.push("Mail de l’employeur");
    if (!visitePaysInterdits.trim()) errors.push("Visite de certains pays depuis 2011");
    if (visitePaysInterdits.toLowerCase() === 'oui') {
      errors.push(
        "En sélectionnant OUI vous n’êtes pas éligibles à l’ESTA, la demande de visa doit impérativement être déposée auprès du consulat des États-Unis à Paris."
      );
    }

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    visiteUSA,
    numVisa,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    nomPrenomPere,
    nomPrenomMere,
    nomPrenomContactUrgence,
    telephoneContactUrgence,
    mailContactUrgence,
    adresseHebergement,
    codePostalHebergement,
    villeHebergement,
    telephoneHebergement,
    mailHebergement,
    nomEmployeur,
    adresseEmployeur,
    codePostalEmployeur,
    villeEmployeur,
    telephoneEmployeur,
    mailEmployeur,
    visitePaysInterdits,
  ]);

  // Récupération de la liste des voyageurs depuis le localStorage
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(
    (v) => v.id.toString() === selectedVoyageur
  );

  // Empêcher la fermeture accidentelle de l’onglet
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const handleBack = () => {
    if (
      window.confirm(
        "Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?"
      )
    ) {
      navigate(-1);
    }
  };

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) formatted += '/';
      formatted += numbers[i];
    }
    return formatted;
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string | null = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;
      const data = {
        etatFormulaire,
        idUtilisateur: userId || '',
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart,
        dateSortie,
        visiteUSA,
        numVisa: visiteUSA.toLowerCase() === 'oui' ? numVisa : '',
        nomPrenomPere,
        nomPrenomMere,
        nomPrenomContactUrgence,
        telephoneContactUrgence,
        mailContactUrgence,
        adresseHebergement,
        codePostalHebergement,
        villeHebergement,
        telephoneHebergement,
        mailHebergement,
        nomEmployeur,
        adresseEmployeur,
        codePostalEmployeur,
        villeEmployeur,
        telephoneEmployeur,
        mailEmployeur,
        visitePaysInterdits,
        assuranceVisa,
        prixconsulat,
        prixintervention,
      };

      // Envoi des données au backend
      await axios.post(
        'https://nodejs-appli.visamonde.com/validerDemande',
        data
      );
      setIsLoading(false);
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  // Fonction vide pour onClick si disabled
  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validite}
              <br />
              {informations}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map((v) => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Champs pour les dates */}
          <TextField
            label="Date de départ (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Visite USA */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="visiteUSA-label">Avez-vous visité les États-Unis ?</InputLabel>
            <Select
              labelId="visiteUSA-label"
              id="visiteUSA-select"
              value={visiteUSA}
              label="Avez-vous visité les États-Unis ?"
              onChange={(e: SelectChangeEvent) => setVisiteUSA(e.target.value)}
            >
              <MenuItem value="oui">Oui</MenuItem>
              <MenuItem value="non">Non</MenuItem>
            </Select>
          </FormControl>
          {visiteUSA.toLowerCase() === 'oui' && (
            <TextField
              label="Numéro du dernier visa/ESTA"
              placeholder="Numéro du visa"
              value={numVisa}
              onChange={(e) => setNumVisa(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}

          {/* Champs supplémentaires */}
          <TextField
            label="Nom et prénom du père"
            placeholder="Nom et prénom du père"
            value={nomPrenomPere}
            onChange={(e) => setNomPrenomPere(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom et prénom de la mère"
            placeholder="Nom et prénom de la mère"
            value={nomPrenomMere}
            onChange={(e) => setNomPrenomMere(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom et prénom du contact en cas d'urgence"
            placeholder="Nom et prénom du contact"
            value={nomPrenomContactUrgence}
            onChange={(e) => setNomPrenomContactUrgence(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone du contact"
            placeholder="Téléphone du contact"
            value={telephoneContactUrgence}
            onChange={(e) => setTelephoneContactUrgence(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mail du contact"
            placeholder="Mail du contact"
            value={mailContactUrgence}
            onChange={(e) => setMailContactUrgence(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de l’hébergement"
            placeholder="Adresse de l’hébergement"
            value={adresseHebergement}
            onChange={(e) => setAdresseHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal de l’hébergement"
            placeholder="Code postal"
            value={codePostalHebergement}
            onChange={(e) => setCodePostalHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville de l’hébergement"
            placeholder="Ville"
            value={villeHebergement}
            onChange={(e) => setVilleHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone de l’hébergement"
            placeholder="Téléphone"
            value={telephoneHebergement}
            onChange={(e) => setTelephoneHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mail de l’hébergement"
            placeholder="Mail"
            value={mailHebergement}
            onChange={(e) => setMailHebergement(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom de l'employeur"
            placeholder="Nom de l'employeur"
            value={nomEmployeur}
            onChange={(e) => setNomEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de l’employeur"
            placeholder="Adresse de l’employeur"
            value={adresseEmployeur}
            onChange={(e) => setAdresseEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code postal de l’employeur"
            placeholder="Code postal"
            value={codePostalEmployeur}
            onChange={(e) => setCodePostalEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Ville de l’employeur"
            placeholder="Ville"
            value={villeEmployeur}
            onChange={(e) => setVilleEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone de l’employeur"
            placeholder="Téléphone"
            value={telephoneEmployeur}
            onChange={(e) => setTelephoneEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mail de l’employeur"
            placeholder="Mail"
            value={mailEmployeur}
            onChange={(e) => setMailEmployeur(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="visitePaysInterdits-label">
            Avez-vous visité, depuis 2011, Cuba, l’Iran, l'Irak, la Libye, la Somalie, le Soudan, la Syrie ou le Yémen ?
            </InputLabel>
            <Select
              labelId="visitePaysInterdits-label"
              id="visitePaysInterdits-select"
              value={visitePaysInterdits}
              label="Avez-vous visité certains pays depuis 2011 ?"
              onChange={(e: SelectChangeEvent) => setVisitePaysInterdits(e.target.value)}
            >
              <MenuItem value="oui">Oui</MenuItem>
              <MenuItem value="non">Non</MenuItem>
            </Select>
          </FormControl>
          {visitePaysInterdits.toLowerCase() === 'oui' && (
            <Box sx={{ backgroundColor: '#ffcccb', p: 2, mb: 2 }}>
              <Typography variant="body1" align="center">
                En sélectionnant OUI vous n’êtes pas éligibles à l’ESTA, la demande de visa doit impérativement être déposée auprès du consulat des États-Unis à Paris.
              </Typography>
            </Box>
          )}

          {/* Module Assurance (identique à FormInde) */}
          <AssuranceVisaComponent
            assuranceVisa={assuranceVisa}
            setAssuranceVisa={setAssuranceVisa}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />

          {/* Récapitulatif du total */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixconsulat}€</Typography>
            <Typography variant="body1">Frais de service : {prixintervention}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* Affichage des erreurs / état du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de soumission */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormUSA;
