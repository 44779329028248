import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../Services'; // Vérifiez le chemin de votre service
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import logo from '../assets/logo.png'; // Retirez l'import si vous utilisez /public

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async () => {
    setLoginError(false);
    if (!email.trim() || !password.trim()) {
      setLoginError(true);
      return;
    }

    try {
      const user = await login(email, password);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('mail', email);

      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
        localStorage.setItem('rememberedPassword', password);
      } else {
        localStorage.removeItem('rememberedEmail');
        localStorage.removeItem('rememberedPassword');
      }

      setEmail('');
      setPassword('');
      navigate('/retour');
    } catch (error) {
      setLoginError(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Paper
        elevation={5}
        sx={{
          width: '100%',
          maxWidth: 400,
          p: 4,
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src="/logo.png"  // On utilise /logo.png
          alt="Logo"
          sx={{ width: 215, height: 59, mb: 2, mx: 'auto' }}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Mot de passe"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="primary"
            />
          }
          label="Se souvenir de moi"
          sx={{ alignSelf: 'flex-start', ml: 1 }}
        />
        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleLogin}
        >
          Connexion
        </Button>
        {loginError && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            Utilisateur ou mot de passe erroné
          </Typography>
        )}
        <Box sx={{ mt: 2 }}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/signup')}
            sx={{ display: 'block', color: 'green', mb: 1 }}
          >
            Créer un compte
          </Link>
          {/* <Link
  component="button"
  variant="body2"
  onClick={() => navigate('/forgot-password')}
  sx={{ display: 'block', color: 'green' }}
>
  Mot de passe oublié
</Link> */}
        </Box>
        <Typography variant="caption" display="block" sx={{ mt: 2, textAlign: 'right' }}>
          v1.0.13
        </Typography>
      </Paper>
    </Box>
  );
};

export default LoginScreen;
