import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import PaysGrid from '../components/PaysGrid';
import { recupererListeVisas, recupererListePays, recupererListeVoyageurs } from '../Services';
import { Visas } from '../models/Visas'; // Importation de l'interface depuis le modèle

export interface Pays {
  id: number;
  libelle: string;
  visible: string;
  url_infos: string;
  url_flag: string;
  url_image: string;
}

const VisasScreen: React.FC = () => {
  const navigate = useNavigate();

  const [pays, setPays] = useState<Pays[]>([]);
  const [paysVisibles, setPaysVisibles] = useState<Pays[]>([]);
  const [paysSelectionne, setPaysSelectionne] = useState<number | null>(null);
  const [visasFiltres, setVisasFiltres] = useState<Visas[]>([]);
  const [urlInfoDestination, setUrlInfoDestination] = useState<string | null>(null);

  // Récupérer l'URL du pays depuis le localStorage
  const getUrlInfos = () => {
    try {
      const url = localStorage.getItem('pays_url_infos');
      if (url) {
        setUrlInfoDestination(url);
      }
    } catch (e) {
      console.error("Erreur lors de la récupération de l'URL depuis localStorage:", e);
    }
  };

  // Lors de la sélection d'un pays
  const handlePaysSelect = (paysId: number | null) => {
    const selectedPays = pays.find((p) => p.id === paysId);
    if (selectedPays) {
      setPaysSelectionne(selectedPays.id);
      try {
        localStorage.setItem('pays_url_infos', selectedPays.url_infos + '?appli=yes');
        console.log('URL du pays sauvegardée dans localStorage:', selectedPays.url_infos);
        getUrlInfos();
      } catch (e) {
        console.error("Erreur lors de la sauvegarde de l'URL dans localStorage:", e);
      }
    }
  };

  // Navigation en fonction du titre du visa et du champ "classique"
  const navigateBasedOnTitle = (
    title: string,
    prixconsulat: number,
    prixintervention: number,
    url_flag: string,
    validite: string,
    informations: string,
    classique: number
  ) => {
    // Vérifier si c'est un visa classique pour l'Arabie Saoudite
    if (classique === 1 && title.includes('Arabie Saoudite')) {
      navigate('/classique_formulaire_arabie_saoudite', {
        state: { title, prixconsulat, prixintervention, url_flag, validite, informations },
      });
      return;
    } else if (title.includes('Inde')) {
      navigate('/formulaire_inde', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('USA')) {
      navigate('/formulaire_usa', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Angola')) {
      navigate('/formulaire_angola', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Arabie Saoudite')) {
      navigate('/formulaire_arabie_saoudite', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Australie')) {
      navigate('/formulaire_australie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Azerbaidjan')) {
      navigate('/formulaire_azerbaidjan', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Bahreïn')) {
      navigate('/formulaire_bahrein', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Benin')) {
      navigate('/formulaire_benin', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Burkina Faso')) {
      navigate('/formulaire_burkina_faso', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Cambodge')) {
      navigate('/formulaire_cambodge', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Cameroun')) {
      navigate('/formulaire_cameroun', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Canada')) {
      navigate('/formulaire_canada', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Cap-Vert')) {
      navigate('/formulaire_cap_vert', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes('Corée Du Sud')) {
      navigate('/formulaire_coree_du_sud', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Cuba")) {
      navigate('/formulaire_cuba', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Djibouti")) {
      navigate('/formulaire_djibouti', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Dubai")) {
      navigate('/formulaire_dubai', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Egypte")) {
      navigate('/formulaire_egypte', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Ethiopie")) {
      navigate('/formulaire_ethiopie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Gabon")) {
      navigate('/formulaire_gabon', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Géorgie")) {
      navigate('/formulaire_georgie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Guinée") && !title.includes("Papouasie")) {
      navigate('/formulaire_guinee', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Indonésie")) {
      navigate('/formulaire_indonesie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Jordanie")) {
      navigate('/formulaire_jordanie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Kenya")) {
      navigate('/formulaire_kenya', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Kirghizistan")) {
      navigate('/formulaire_kirghizistan', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Koweït")) {
      navigate('/formulaire_koweit', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Laos")) {
      navigate('/formulaire_laos', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Lesotho")) {
      navigate('/formulaire_lesotho', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Madagascar")) {
      navigate('/formulaire_madagascar', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Malawi")) {
      navigate('/formulaire_malawi', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Mongolie")) {
      navigate('/formulaire_mongolie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Mozambique")) {
      navigate('/formulaire_mozambique', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Myanmar")) {
      navigate('/formulaire_myanmar', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Nouvelle-Zélande")) {
      navigate('/formulaire_nouvelle_zelande', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Oman")) {
      navigate('/formulaire_oman', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Ouganda")) {
      navigate('/formulaire_ouganda', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Ouzbékistan")) {
      navigate('/formulaire_ouzbekistan', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Pakistan")) {
      navigate('/formulaire_pakistan', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Papouasie")) {
      navigate('/formulaire_papouasie_ng', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("République Dominicaine")) {
      navigate('/formulaire_republique_dominicaine', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Seychelles")) {
      navigate('/formulaire_seychelles', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Sierra Leone")) {
      navigate('/formulaire_sierra_leone', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Singapour")) {
      navigate('/formulaire_singapour', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Sri Lanka")) {
      navigate('/formulaire_sri_lanka', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Tadjikistan")) {
      navigate('/formulaire_tadjikistan', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Tanzanie")) {
      navigate('/formulaire_tanzanie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Thailande")) {
      navigate('/formulaire_thailande', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Vietnam")) {
      navigate('/formulaire_vietnam', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Zambie")) {
      navigate('/formulaire_zambie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Zimbabwe")) {
      navigate('/formulaire_zimbabwe', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Togo")) {
      navigate('/formulaire_togo', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else if (title.includes("Turquie")) {
      navigate('/formulaire_turquie', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    } else {
      navigate('/formulaire_template', { state: { title, prixconsulat, prixintervention, url_flag, validite, informations } });
    }
  };

  const checkVoyageursAndNavigate = async (
    title: string,
    prixconsulat: number,
    prixintervention: number,
    url_flag: string,
    validite: string,
    informations: string,
    classique: number
  ) => {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      const user = JSON.parse(userJson);
      const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
      if (listeVoyageurs.length > 0) {
        navigateBasedOnTitle(title, prixconsulat, prixintervention, url_flag, validite, informations, classique);
      } else {
        alert('Aucun voyageur, veuillez créer un voyageur avant de faire une demande.');
        navigate('/formulaire_creation_voyageur');
      }
    } else {
      alert('Erreur, utilisateur non trouvé.');
    }
  };

  useEffect(() => {
    const fetchPays = async () => {
      const paysListe = await recupererListePays();
      setPays(paysListe);
      const visiblePays = paysListe.filter((p) => p.visible === 'oui');
      setPaysVisibles(visiblePays);
    };
    fetchPays();
  }, []);

  useEffect(() => {
    const fetchVisasFiltres = async () => {
      if (paysSelectionne !== null) {
        const visasListe = await recupererListeVisas();
        const filtered = visasListe.filter((v) => v.id_pays === paysSelectionne);
        setVisasFiltres(filtered);
      }
    };
    fetchVisasFiltres();
  }, [paysSelectionne]);

  const handleRetourPays = () => {
    setPaysSelectionne(null);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 2,
        overflowY: 'auto',
      }}
    >
      <Header />

      {paysSelectionne ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Button variant="contained" color="success" onClick={handleRetourPays}>
              Retour aux Pays
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                if (urlInfoDestination) {
                  window.open(urlInfoDestination, '_blank');
                } else {
                  alert('Aucune URL disponible.');
                }
              }}
            >
              Infos Pays
            </Button>
          </Box>

          <Grid container spacing={2}>
            {visasFiltres.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    checkVoyageursAndNavigate(
                      item.titre,
                      item.prixconsulat ?? 0,
                      item.prixintervention ?? 0,
                      item.url,
                      item.validite,
                      item.informations ?? '',
                      item.classique ?? 0
                    )
                  }
                >
                  <CardContent>
                    <Typography variant="h6" align="center">
                      {item.titre}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                      {item.validite}
                    </Typography>
                    <CardMedia
                      component="img"
                      image={item.url}
                      alt={item.titre}
                      sx={{ height: 150, objectFit: 'contain', my: 1 }}
                    />
                    <Typography variant="body2" align="center">
                      Frais de visa: {item.prixconsulat}€
                    </Typography>
                    <Typography variant="body2" align="center">
                      Frais de services: {item.prixintervention}€
                    </Typography>
                    <Typography variant="body2" align="center">
                      {item.informations}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Box sx={{ width: '95%', mx: 'auto', mt: 2, mb: 2 }}>
            <TextField
              select
              label="Sélectionner un pays"
              value={paysSelectionne || ''}
              onChange={(e) => handlePaysSelect(Number(e.target.value))}
              fullWidth
              SelectProps={{
                sx: { backgroundColor: 'white' },
              }}
            >
              {pays.map((paysItem) => (
                <MenuItem key={paysItem.id} value={paysItem.id}>
                  {paysItem.libelle}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <PaysGrid paysVisibles={paysVisibles} onSelectPays={handlePaysSelect} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default VisasScreen;
