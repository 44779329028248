import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';

interface FormScreenRouteParams {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
}

const FormDjibouti: React.FC = () => {
  // Récupération des paramètres passés via react-router
  const location = useLocation();
  const navigate = useNavigate();
  const state = (location.state || {}) as FormScreenRouteParams;
  const { title, prixconsulat, prixintervention, demandeId, url_flag, validite, informations } = state;
  const isEditMode = demandeId != null;

  // États de base (issus des paramètres ou valeurs par défaut)
  const [titleState, setTitleState] = useState<string>(title || '');
  const [prixConsulatState, setPrixConsulatState] = useState<number>(prixconsulat || 0);
  const [prixInterventionState, setPrixInterventionState] = useState<number>(prixintervention || 0);
  const [urlFlag, setUrlFlag] = useState<string>(url_flag || '');
  const [validiteState, setValiditeState] = useState<string>(validite || '');
  const [informationsState, setInformationsState] = useState<string>(informations || '');

  // États du formulaire communs
  const [dateDepart, setDateDepart] = useState<string>('');
  const [dateSortie, setDateSortie] = useState<string>('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState<string>('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Champs spécifiques à la demande Djibouti
  const [paysVisites, setPaysVisites] = useState<string>('');
  const [nomEtablissementAccueil, setNomEtablissementAccueil] = useState<string>('');
  const [adresseEtablissementAccueil, setAdresseEtablissementAccueil] = useState<string>('');
  const [telephoneEtablissementAccueil, setTelephoneEtablissementAccueil] = useState<string>('');

  // Gestion des fichiers
  const [letterInvitationFile, setLetterInvitationFile] = useState<File | null>(null);
  const [letterInvitationPreview, setLetterInvitationPreview] = useState<string | null>(null);
  const [billetAllerRetourFile, setBilletAllerRetourFile] = useState<File | null>(null);
  const [billetAllerRetourPreview, setBilletAllerRetourPreview] = useState<string | null>(null);
  const [reservationHotelFile, setReservationHotelFile] = useState<File | null>(null);
  const [reservationHotelPreview, setReservationHotelPreview] = useState<string | null>(null);

  // Calcul du total
  const total =
    prixConsulatState +
    prixInterventionState +
    (assuranceVisa.toLowerCase() === 'oui' ? 20 : 0);

  // Formatage de la date (jj/mm/aaaa)
  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    let formatted = '';
    for (let i = 0; i < numbers.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formatted += '/';
      }
      formatted += numbers[i];
    }
    return formatted;
  };

  // Validation du formulaire
  const validateForm = () => {
    const parseDate = (dateStr: string): Date | null => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);
    const errors: string[] = [];

    // Contrôles sur les dates
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }
    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || (departDate && sortieDate <= departDate)) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de retour');

    // Si c'est un visa affaire, la lettre d'invitation est requise
    if (titleState.toLowerCase().includes('affaire') && !letterInvitationFile) {
      errors.push("Lettre d'invitation");
    }

    // Validation des champs spécifiques
    if (!paysVisites.trim()) errors.push('Pays visités ces trois dernières années');
    if (!nomEtablissementAccueil.trim()) errors.push("Nom de l'établissement d'accueil à Djibouti");
    if (!adresseEtablissementAccueil.trim()) errors.push("Adresse de l'établissement d'accueil à Djibouti");
    if (!telephoneEtablissementAccueil.trim()) errors.push("Téléphone de l'établissement d'accueil à Djibouti");

    if (!billetAllerRetourFile) errors.push("Billet aller-retour");
    if (!reservationHotelFile) errors.push("Réservation hôtel ou certificat d’hébergement");

    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    paysVisites,
    nomEtablissementAccueil,
    adresseEtablissementAccueil,
    telephoneEtablissementAccueil,
    letterInvitationFile,
    billetAllerRetourFile,
    reservationHotelFile,
  ]);

  // Récupération de la liste des voyageurs
  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = localStorage.getItem('user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        // Sélection automatique s’il n’y a qu’un seul voyageur
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };
    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  // Empêcher la fermeture de l’onglet sans confirmation
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Bouton retour
  const handleBack = () => {
    if (
      window.confirm(
        'Les informations saisies ne sont pas enregistrées. Êtes-vous sûr de quitter ce formulaire ?'
      )
    ) {
      navigate(-1);
    }
  };

  // Gestion de l’upload des fichiers avec prévisualisation
  const handleLetterInvitationUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setLetterInvitationFile(file);
      const fileUrl = URL.createObjectURL(file);
      setLetterInvitationPreview(fileUrl);
    }
  };

  const handleBilletAllerRetourUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setBilletAllerRetourFile(file);
      const fileUrl = URL.createObjectURL(file);
      setBilletAllerRetourPreview(fileUrl);
    }
  };

  const handleReservationHotelUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setReservationHotelFile(file);
      const fileUrl = URL.createObjectURL(file);
      setReservationHotelPreview(fileUrl);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = localStorage.getItem('user');
      let userId: string = '';
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }
      const etatFormulaire = champsNonRemplis.length === 0;

      // Construction du FormData
      const formData = new FormData();
      formData.append('etatFormulaire', String(etatFormulaire));
      formData.append('idUtilisateur', userId);
      formData.append('idVoyageur', selectedVoyageur);
      formData.append('titreVisa', titleState);
      formData.append('dateDepart', dateDepart);
      formData.append('dateSortie', dateSortie);
      formData.append('paysVisites', paysVisites);
      formData.append('nomEtablissementAccueil', nomEtablissementAccueil);
      formData.append('adresseEtablissementAccueil', adresseEtablissementAccueil);
      formData.append('telephoneEtablissementAccueil', telephoneEtablissementAccueil);
      formData.append('assuranceVisa', assuranceVisa);
      formData.append('prixconsulat', String(prixConsulatState));
      formData.append('prixintervention', String(prixInterventionState));

      // Si visa affaire, ajout de la lettre d'invitation
      if (titleState.toLowerCase().includes('affaire') && letterInvitationFile) {
        formData.append('lettreInvitation', letterInvitationFile);
      }
      // Ajout des autres documents
      if (billetAllerRetourFile) {
        formData.append('billetAllerRetour', billetAllerRetourFile);
      }
      if (reservationHotelFile) {
        formData.append('reservationHotel', reservationHotelFile);
      }

      // Envoi vers le backend
      await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setIsLoading(false);
      // Navigation après succès
      navigate('/retour', { state: { tabValue: 2 } });
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const disabledPressHandler = () => {};

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ backgroundColor: '#eeeeee', minHeight: '100vh', py: 4 }}
    >
      <Container maxWidth="md">
        <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 2 }}>
          {/* En-tête */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            {urlFlag && (
              <Box
                component="img"
                src={urlFlag}
                alt="Flag"
                sx={{ width: 50, height: 30, mb: 1, mx: 'auto' }}
              />
            )}
            <Typography variant="h5" component="h1" gutterBottom>
              Formulaire pour {titleState}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {validiteState}
              <br />
              {informationsState}
            </Typography>
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 1 }}>
              Retour
            </Button>
          </Box>

          {/* Sélection du voyageur si non en mode édition */}
          {!isEditMode && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="voyageur-select-label">Sélectionner un voyageur</InputLabel>
              <Select
                labelId="voyageur-select-label"
                id="voyageur-select"
                value={selectedVoyageur}
                label="Sélectionner un voyageur"
                onChange={(e: SelectChangeEvent) => setSelectedVoyageur(e.target.value)}
              >
                {voyageurs.map(v => (
                  <MenuItem key={v.id} value={v.id.toString()}>
                    {v.nom} {v.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Avatar du voyageur sélectionné */}
          {selectedVoyageurObj && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Avatar
                src={selectedVoyageurObj.url || 'https://example.com/default-avatar.png'}
                alt="Avatar"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
          )}

          {/* Champs de dates */}
          <TextField
            label="Date de départ de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateDepart}
            onChange={(e) => setDateDepart(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date de retour de votre voyage (jj/mm/aaaa)"
            placeholder="jj/mm/aaaa"
            value={dateSortie}
            onChange={(e) => setDateSortie(formatDate(e.target.value))}
            fullWidth
            margin="normal"
          />

          {/* Lettre d'invitation (pour visa affaire) */}
          {titleState.toLowerCase().includes('affaire') && (
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Lettre d'invitation :
              </Typography>
              <input type="file" accept="image/*,application/pdf" onChange={handleLetterInvitationUpload} />
              {letterInvitationPreview && (
                <Box sx={{ mt: 2 }}>
                  <img
                    src={letterInvitationPreview}
                    alt="Prévisualisation de la lettre d'invitation"
                    style={{ maxWidth: '100%', maxHeight: 300 }}
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Champs spécifiques à Djibouti */}
          <TextField
            label="Pays visités ces trois dernières années"
            placeholder="Liste des pays visités"
            value={paysVisites}
            onChange={(e) => setPaysVisites(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom de l'établissement d'accueil à Djibouti"
            placeholder="Nom de l'établissement"
            value={nomEtablissementAccueil}
            onChange={(e) => setNomEtablissementAccueil(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Adresse de l'établissement d'accueil à Djibouti"
            placeholder="Adresse complète"
            value={adresseEtablissementAccueil}
            onChange={(e) => setAdresseEtablissementAccueil(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Téléphone de l'établissement d'accueil à Djibouti"
            placeholder="Numéro de téléphone"
            value={telephoneEtablissementAccueil}
            onChange={(e) => setTelephoneEtablissementAccueil(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Uploads de documents supplémentaires */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Billet aller-retour :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleBilletAllerRetourUpload} />
            {billetAllerRetourPreview && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={billetAllerRetourPreview}
                  alt="Prévisualisation du billet aller-retour"
                  style={{ maxWidth: '100%', maxHeight: 300 }}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              Réservation hôtel ou certificat d’hébergement :
            </Typography>
            <input type="file" accept="image/*,application/pdf" onChange={handleReservationHotelUpload} />
            {reservationHotelPreview && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={reservationHotelPreview}
                  alt="Prévisualisation de la réservation hôtel"
                  style={{ maxWidth: '100%', maxHeight: 300 }}
                />
              </Box>
            )}
          </Box>

          {/* Module Assurance */}
          <Box sx={{ my: 2 }}>
            <AssuranceVisaComponent
              assuranceVisa={assuranceVisa}
              setAssuranceVisa={setAssuranceVisa}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
          </Box>

          {/* Récapitulatif des frais */}
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="body1">Frais de visa : {prixConsulatState}€</Typography>
            <Typography variant="body1">Frais de service : {prixInterventionState}€</Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total : {total}€
            </Typography>
          </Box>

          {/* Indication de l'état du formulaire */}
          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 1,
              backgroundColor: champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc',
            }}
          >
            {champsNonRemplis.length > 0 ? (
              <>
                <Typography variant="h6" align="center">
                  Formulaire incomplet.
                </Typography>
                {champsNonRemplis.map((champ, index) => (
                  <Typography key={index}>{champ}</Typography>
                ))}
              </>
            ) : (
              <Typography align="center">Formulaire complet</Typography>
            )}
          </Box>

          {/* Bouton de validation */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={isFormValid ? handleSubmit : disabledPressHandler}
                disabled={!isFormValid}
                sx={{ backgroundColor: isFormValid ? 'black' : '#A9A9A9' }}
              >
                Valider ma demande
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FormDjibouti;
